import carousel0 from '~/assets/images/carousel/carousel__1.jpg'
import carousel1 from '~/assets/images/carousel/carousel__2.jpg'
import carousel2 from '~/assets/images/carousel/carousel__3.jpg'
import carousel3 from '~/assets/images/carousel/carousel__4.jpg'
import carousel4 from '~/assets/images/carousel/carousel__5.jpg'
import footerBackground from '~/assets/images/home__bottom.jpg'
import HOW from '~/assets/images/home__how.jpg'
import WhoWeAre from '~/assets/images/home_who_we_are.jpg'
import App from '~/components/app'
import type { ConnectedProps } from '~/connect'
import { scrollIntoView } from '~/lib/utils/scroll'
import '~/styles/pages/home.scss'

const Home: React.FC<ConnectedProps> = () => {
  const about = useRef(null)
  const analytics = useAnalytics()
  const scrollToAbout = () => scrollIntoView(about.current)
  return (
    <App className='relative full' page='home'>
      <Container
        className='mx-auto max-w-2xl px-4 sm:px-8'
        name='home'
        innerClass='space-y-12 sm:space-y-16'
      >
        <div className='rounded-xl shadow-md xs:(relative p-4 space-y-4) sm:(p-8 space-y-8) lg:(p-16 space-y-16) 2xl:p-32 children:(max-w-md mx-auto)'>
          <div className='space-y-2 <xs:(relative p-4) xs:px-4 md:text-center lg:space-y-4'>
            <Carousel
              className='absolute inset-0 -z-1 rounded-xl overflow-hidden <xs:rounded-b-none'
              images={[carousel0, carousel1, carousel2, carousel3, carousel4]}
              interval={5000}
            />
            <h1 className='font-bold text-gradient text-2xl xs:text-3xl sm:text-4xl'>
              Lighthouse helps you find the right LGBTQIA+ friendly healthcare
              and wellness providers for you.
            </h1>
            <button
              className='py-2.5 px-4 blue__bg'
              onClick={() => scrollToAbout()}
            >
              Learn more
            </button>
          </div>
          <SearchBar
            className='bg-white rounded p-4 xs:p-5 <xs:(rounded-b-xl shadow-none)'
            context='home_banner'
          />
        </div>
        <BlogPosts className='rounded-xl shadow-md bg-white p-8 pb-4' />
        <figure
          className='md:(grid grid-flow-row-dense items-center) @md:grid-cols-5 lg:grid-cols-2'
          id='home__about'
          ref={about}
        >
          <img
            className='rounded-xl shadow-md w-full @md:(col-start-4 col-span-2) lg:col-start-2'
            src={WhoWeAre}
          />
          <figcaption className='p-8 space-y-2 @md:col-span-3 lg:p-16'>
            <h1>Who we are</h1>
            <p>
              We are a group of NYC-based healthcare providers devoting our
              careers to caring for LGBTQ+ patients and specializing in LGBTQ+
              health issues.
            </p>
            <p className='blue'>
              We know that, as an LGBTQ+ person (or someone who goes without
              labels entirely), you have specific healthcare needs. Lighthouse
              connects you with providers nearby who understand those needs and
              strive to eliminate stigma, disparities or barriers in LGBTQ+
              health treatment.
            </p>
            <p>
              We're up and running in New York City and are expanding rapidly to
              serve the LGBTQ+ community nationwide.
            </p>
            <p>
              LGBTQ+ people want more and deserve better. That's why we created
              Lighthouse. We're here to provide healthcare that caters to you.
            </p>
          </figcaption>
        </figure>
        <div className='space-y-4'>
          <div className='max-w-prose mx-auto space-y-2 text-center'>
            <h1>Why Lighthouse?</h1>
            <p>
              Finding the right doctor or specialist is daunting for anyone, but
              it can be particularly challenging for people who identify as gay,
              lesbian, bisexual, transgender, or queer. Your unique health needs
              require expert treatment, and often discrimnation can makes
              attaining treatment more difficult. Lighthouse strives to help you
              find a health care specialist who not only understands your needs,
              but lives them or specializes in them.
            </p>
          </div>
          <div className='rounded-xl shadow-xl bg-white <lg:(children:px-4 children:py-8) lg:(flex children:p-8)'>
            <div className='space-y-8 relative after:(content absolute bottom-0 inset-x-0 lg:left-auto lg:top-0 border-0 border-b lg:border-b-none lg:border-r border-solid border-opacity-10 border-gray)'>
              <div>
                <h1 className='text-green'>50%</h1>
                <h4 className='!mb-0'>
                  More than 50% of LGBTQ people experience some form of
                  discrimination in healthcare settings at some point in their
                  lives
                </h4>
              </div>
              <div>
                {new Array(5).fill(0).map((_, i) => (
                  <IconElPerson
                    key={i}
                    className={`-mx-1.5 mb-1 text-2xl ${
                      i ? 'opacity-25' : 'text-red'
                    }`}
                  />
                ))}
                <h4 className='!mb-0'>
                  1 in 5 trans people have been denied healthcare because of
                  their gender identity
                </h4>
              </div>
            </div>
            <ul className='!ml-0 grid gap-8 grid-cols-2 sm:grid-cols-3'>
              <li>
                <h1 className='blue'>43%</h1>
                <p>
                  43% of adults ages 45 to 70 don't disclose their sexual
                  orientation to their healthcare provider.
                </p>
              </li>
              <li>
                <h1 className='pink'>80%</h1>
                <p>
                  Nearly 80% of providers said in a recent survey they think it
                  would be inappropriate and uncomfortable to ask about a
                  patient’s sexual orientation or gender identity.
                </p>
              </li>
              <li>
                <h1 className='purple'>10%</h1>
                <p>
                  Only 10% of LGBTQ people say they would choose not to disclose
                  their sexual orientation and gender identity to their
                  healthcare provider.
                </p>
              </li>
              <li>
                <h1 className='green'>3X</h1>
                <p>
                  When someone experiences discrimination in healthcare once,
                  they are 3X more likely to postpone follow-up care.
                </p>
              </li>
              <li>
                <h1 className='red'>79%</h1>
                <p>
                  In a 2015 study, 79% of first year medical students showed
                  implicit bias against gay/lesbian people and 50% showed
                  explicit bias.
                </p>
              </li>
              <li>
                <h1 className='orange'>92%</h1>
                <p>
                  92% of LGBTQ+ people believe that it’s important to find
                  LGBTQ- affirming healthcare providers.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className='sm:(grid grid-cols-2 gap-4) md:gap-8 xl:items-center'>
          <img src={HOW} className='rounded-xl shadow-md w-full <sm:hidden' />
          <div className='space-y-4'>
            <h2>What we do</h2>
            <ul className='space-y-4 children:(space-y-2 children:m-0)'>
              <li>
                <h4 className='blue'>Vetting</h4>
                <p>
                  We only work with providers with ample experience caring for
                  and treating LGBTQ individuals. We carefully vet our doctors,
                  dentists, therapists, and other health professionals before
                  listing them to ensure your safety.
                </p>
              </li>
              <li>
                <h4 className='blue'>Simplicity</h4>
                <p>
                  Finding a doctor who understands your needs should be easy.
                  With Lighthouse, it is. Simply type in your zip code and we'll
                  find you LGBTQ-affirming providers nearby and highlight
                  providers with expertise in the specialties you need, so you
                  can find the right care the first time.
                </p>
              </li>
              <li>
                <h4 className='blue'>Community</h4>
                <p>
                  We’re bringing LGBTQ care to the forefront by building a
                  community of users and providers in New York City, starting
                  with hosting events focused on LGBTQ+ wellness. We're also
                  working towards providing more affordable healthcare services
                  and offering training for practitioners who want to better
                  understand our community's unique healthcare needs.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className='home__block home__bottom'>
          <h1>For Providers</h1>
          <p>
            In addition to connecting people to much-needed care, we're helping
            providers dedicated to treating LGBTQ+ health concerns find new
            clients and grow their practices. We're building a dedicated
            community around LGBTQ+ wellness, and are developing events,
            workshops, and training opportunities to better serve the LGBTQ
            community. Join our movement!
          </p>
          <a
            onClick={() => {
              analytics?.track('Apply here clicked')
              window.open(
                'https://www.lighthouse.lgbt/join?ref=chicago',
                '_blank'
              )
            }}
            className='button__link standard__button blue__bg white'
          >
            Apply here
          </a>
        </div>
        <div aria-hidden className='pointer-events-none h-45vw' />
        <img
          className='-z-1000 pointer-events-none absolute inset-x-0 bottom-0 w-full h-auto'
          src={footerBackground}
        />
      </Container>
    </App>
  )
}
export default connect()(Home)
