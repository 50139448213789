import { Component } from 'react'

/**
 */
class Logout extends Component {
  componentDidMount() {
    const { logout, history } = this.props
    logout()
    history.push('/')
  }

  render() {
    return null
  }
}

export default connect()(Logout)
