import type { ComponentType } from 'react'
import { Component } from 'react'

export type FadeProps = {
  loaded?: boolean
  withFade?: boolean
}

export const createFadeIn = <P extends object>(
  WrappedCmp: ComponentType<P>
) => {
  return class FadeIn extends Component<P & FadeProps, { visible: boolean }> {
    static defaultProps = {
      loaded: true,
      withFade: true,
    }

    constructor(props: P & FadeProps) {
      super(props)

      this.state = {
        visible: false,
      }
    }

    componentDidMount() {
      if (this.props.loaded && this.props.withFade) {
        setTimeout(() => {
          this.setState({
            visible: true,
          })
        }, 300)
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.loaded && !prevProps.loaded) {
        setTimeout(() => {
          this.setState({
            visible: true,
          })
        }, 300)
      }
    }

    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return (
        <div className={`fade${this.state.visible ? ' visible' : ''}`}>
          <WrappedCmp {...this.props} visible={this.state.visible} />
        </div>
      )
    }
  }
}
