export const useScript = (
  src: string | undefined,
  onload?: HTMLScriptElement['onload']
) => {
  const prevSrc = usePrevious(src)

  const addScript = (_src: string | undefined) => {
    if (!_src) return

    const script = document.createElement('script')
    script.setAttribute('src', _src)
    script.onload = onload ?? null

    document?.head?.appendChild(script)
  }

  useEffect(() => {
    if (src !== prevSrc) addScript(src)
  }, [src])
}
