import App from '~/components/app'
import { getQueryParam } from '~/lib/utils/url'

import '~/styles/pages/error.scss'

export const ErrorView: React.FC<{ message: string }> = ({ message }) => {
  const location = useLocation()

  return (
    <App className='public nomatch' page='error'>
      <Container name='error'>
        <h2>{message || getQueryParam(location.search, 'error_message')}</h2>
        <Link to='/' className='button__link naked'>
          Return Home
        </Link>
      </Container>
    </App>
  )
}
