import type { FC } from 'react'

import '~/styles/components/navbar.scss'

/**
 * Navbar
 */
export const Navbar: FC<{ text?: string }> = ({ text }) => {
  const [active, setActive] = useState(false)

  const home = useMatch({
    end: true,
    path: '/',
  })

  const navigate = useNavigate()

  const goHome = () => {
    if (!home) navigate('/')
  }

  const toggleMenu = (active: boolean) => {
    if (active) {
      document.body.className = 'withNav'

      setTimeout(() => setActive(active), 1)
    } else {
      setActive(active)

      setTimeout(() => {
        document.body.className = ''
      }, 500) // 500 is the duration of the animation
    }
  }

  return (
    <nav className={active ? 'is-active' : ''}>
      <div className='logo' onClick={() => goHome()}>
        <span className='icon-lighthouse' />

        <div className='logo__text'>
          <h2>Lighthouse</h2>

          {text && <span className='logo__text'>{text}</span>}
        </div>
      </div>

      <ul className='nav__list'>
        <li>
          <Link to='/'>Home</Link>
        </li>
        <li>
          <a href='https://blog.lighthouse.lgbt'>Blog</a>
        </li>
        <li>
          <a href='https://www.lighthouse.lgbt/join?referer=chicago'>
            Join Our Network
          </a>
        </li>
        <li>
          <a href='https://www.lighthouse.lgbt/login'>Log In</a>
        </li>
      </ul>

      <button
        className={`naked hamburger hamburger--spin${
          active ? ' is-active' : ''
        }`}
        type='button'
        onClick={() => toggleMenu(!active)}
      >
        <span className='hamburger-box'>
          <span className='hamburger-inner'></span>
        </span>
      </button>
    </nav>
  )
}
