import { $fetch } from 'ohmyfetch'

import { get } from '~/lib/api'

export const getProfessional = (professional_id: number) =>
  get(`/professionals/${professional_id}`)

export const contactProfessional = (body: {
  initial_message: string
  patient_email: string
  patient_first_name: string
  patient_last_name: string
  patient_phone: string
  patient_pronouns: string
  patient_voicemail: boolean
  professional_id: number
  recaptchaToken: string
}) =>
  $fetch('/api/provider/contact', {
    body,
    method: 'POST',
  })
