import type { FC } from 'react'

import App from '~/components/app'
import type { ConnectedProps } from '~/connect'
import type { BlogPost } from '~/store/modules/blog'
import { transformToBlogPost } from '~/store/modules/blog'
import '~/styles/pages/blog.scss'

const NOMATCH = '/404'

const BlogPostInner = createFadeIn<{ post: BlogPost }>(
  ({ post: { content, image, title } }) => (
    <div className='blog__content'>
      <div
        className='blog__content__hero hero'
        style={{ backgroundImage: `url(${image || undefined}` }}
      >
        <h1>{title}</h1>
        <div className='text-center <2xs:(max-w-full -mt-8 border border-b) <lg:(w-140 max-w-9/10) lg:(mt-16 max-w-9/10) 2xl:(mt-0 max-w-150)'>
          <h3 className='mb-2'>Start searching for a provider below.</h3>
          <SearchBar className='bg-white p-4 rounded xs:(rounded p-5) scaling' />
        </div>
      </div>
      <div
        className='blog__content__body'
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  )
)

const BlogPostPage: FC<ConnectedProps> = () => {
  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState<BlogPost>()

  const match = useMatch('/blog/:slug')
  const navigate = useNavigate()

  const getPost = async () => {
    const [[post] = []] = await tc(
      () => getBlogPost(match!.params.slug!),
      () => navigate(NOMATCH, { replace: true })
    )

    if (post) setPost(transformToBlogPost(post))
    setLoading(false)
  }

  useEffect(() => {
    getPost()
  }, [])

  return (
    <App
      className='full'
      title={match?.params.slug || 'Post not found'}
      page='blog'
    >
      <Container
        name='blog'
        className='center'
        navText={match?.params.slug || undefined}
      >
        {loading || !post ? (
          <Loader className='dark' />
        ) : (
          <BlogPostInner {...{ post }} />
        )}
      </Container>
    </App>
  )
}

export default connect()(BlogPostPage)
