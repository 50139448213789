/**
 * Action types as consant
 */
export const TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR'
export const UPDATE_LOADING = 'UPDATE_LOADING'
export const TOGGLE_CONTACT_MODAL = 'TOGGLE_CONTACT_MODAL'

export type UIStore = {
  snackbarText: string
  showSnackbar: boolean
  snackbarClass: string
  loading: boolean
  contactModal: boolean
  contactModalClass: string
}

const initialState: UIStore = {
  contactModal: false,
  contactModalClass: 'string',
  loading: false,
  showSnackbar: false,
  snackbarClass: '',
  snackbarText: '',
}

/**
 * UI reducer boilerplate example
 */
export default function UIReducer(
  state: UIStore = initialState,
  action: any
): UIStore {
  switch (action.type) {
    case UPDATE_LOADING:
      return { ...state, loading: action.loading }
    case TOGGLE_CONTACT_MODAL:
      return {
        ...state,
        contactModal: action.visible,
        contactModalClass: action.contactModalClass,
      }
    case TOGGLE_SNACKBAR:
      return {
        ...state,
        showSnackbar: action.show,
        snackbarClass: action.snackbarClass,
        snackbarText: action.snackbarText,
      }
    default:
      return state
  }
}

// Action creators
type UIActionCreatorsType = {
  toggleSnackbar: Function
  toggleContactModal: Function
  enableLoading: Function
}

/**
 * Shows or hides the snackbar in state
 * @param  {String}  snackbarText Text of the snackbar
 * @param  {Boolean} show Whether to show or hide, shown by default
 */
const toggleSnackbar =
  (snackbarText = '', show = true, snackbarClass = '') =>
  dispatch => {
    dispatch({
      show,
      snackbarClass,
      snackbarText,
      type: TOGGLE_SNACKBAR,
    })

    // Fire off dispatch to prevent the snackbar from showing up again
    setTimeout(() => {
      dispatch({
        show: false,
        snackbarClass,
        snackbarText,
        type: TOGGLE_SNACKBAR,
      })
    }, 3000)
  }

/**
 * Toggle contact modal
 * @param  {boolean} loading Is loading or not
 */
const toggleContactModal = (visible, className) => ({
  contactModalClass: className,
  type: TOGGLE_CONTACT_MODAL,
  visible,
})

/**
 * Set's loading state
 * @param  {boolean} loading is loading or not
 */
const enableLoading = loading => ({
  loading,
  type: UPDATE_LOADING,
})

const UIActionCreators = {
  enableLoading,
  toggleContactModal,
  toggleSnackbar,
}

export { UIActionCreators, UIActionCreatorsType }
