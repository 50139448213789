/**
 * Checks is passed string is completley empty, also checks for just whitespace
 * @return {Boolean} True if empty, False is not empty
 */
export const isEmptyString = string => {
  return string == null || !/\S/.test(string)
}

export const get = (p, o) => {
  if (p == null || p == undefined) return null
  return p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o)
}

export const anyStringsEmpty = arrayOfStrings => {
  return arrayOfStrings.some(isEmptyString)
}

// https://stackoverflow.com/questions/33076177/getting-name-initials-using-js
export const getInitials = string => {
  const initials = string.match(/\b\w/g) || []
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const deconstructWithPrefix = (object, prefix) => {
  const newObject = {}
  Object.keys(object).forEach((key, index) => {
    newObject[`${prefix}_${key}`] = object[key]
  })
  return newObject
}

// https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
export const formatPhoneNumber = phoneNumberString => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const pluralize = (string, numberOfEls) => {
  if (numberOfEls > 1) {
    return `${string}s`
  } else {
    return string
  }
}
