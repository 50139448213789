import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

// Actions
import type { RootState } from '~/store'
import { professionalActionCreators } from '~/store/modules/professional'
import { searchActionCreators } from '~/store/modules/search'
import type { UIActionCreatorsType } from '~/store/modules/ui'
import { UIActionCreators } from '~/store/modules/ui'

export type ConnectedProps = RootState &
  UIActionCreatorsType &
  typeof searchActionCreators &
  typeof professionalActionCreators

/**
 * Returns baseline state to props e.g. UI and User objects
 * @param {Funciton} customStateToProps A custom funciton to define prop variables from state
 */
const mapStateToProps =
  (customStateToProps: Function | null = null) =>
  state => {
    return {
      professionalView: state.professionalView,
      search: state.search,
      ui: state.ui,
      ...(customStateToProps !== null ? customStateToProps(state) : {}),
    }
  }

/**
 * Returns baseline dispatch props and allows you to include your own dispatch functions
 * @param {Object} actionCreators Object containing dispatch functions that should be available in props
 */
const mapDispatchToProps = actionCreators => dispatch =>
  bindActionCreators(
    {
      ...UIActionCreators,
      ...searchActionCreators,
      ...professionalActionCreators,
      ...actionCreators,
    },
    dispatch
  )

/**
 * Custom connect component
 * @param {*} stateToProp
 * @param {*} actionCreators
 */
export const customConnect = (
  stateToProp?: Parameters<typeof connect>[0],
  actionCreators?: Parameters<typeof connect>[1]
) => connect(mapStateToProps(stateToProp), mapDispatchToProps(actionCreators))

export default customConnect
