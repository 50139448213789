import GoogleMapReact from 'google-map-react'

import type { Professional } from '~/store/modules/professional'

// Re-center map when resizing the window
// const bindResizeListener = (map, maps, bounds) => {
//   maps.event.addDomListenerOnce(map, 'idle', () => {
//     maps.event.addDomListener(window, 'resize', () => {
//       map.fitBounds(bounds)
//     })
//   })
// }

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, address) => {
  if (address !== null) {
    map.setZoom(14)
    map.setCenter(new maps.LatLng(address.latitude, address.longitude))
  }
  // Bind the resize listener
  // bindResizeListener(map, maps, bounds);
}

export const MapView: React.FC<{
  className: string
  professional: Professional
}> = ({ className, professional }) => (
  <div className={className}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: 'AIzaSyCNsVZi3vox_yxn3H332gZgbSoVK7Tksmg' }}
      defaultCenter={{
        lat: 40.73061,
        lng: -73.935242,
      }}
      yesIWantToUseGoogleMapApiInternals
      defaultZoom={11}
      onGoogleApiLoaded={({ map, maps }) =>
        apiIsLoaded(
          map,
          maps,
          professional.addresses.length > 0 ? professional.addresses[0] : null
        )
      }
    >
      {professional.addresses.length > 0 && (
        <Marker
          lat={Number(professional.addresses[0].latitude)}
          lng={Number(professional.addresses[0].longitude)}
        />
      )}
    </GoogleMapReact>
  </div>
)
