import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import logger from 'redux-logger'

import { professionalReducer, uiReducer, searchReducer } from './modules'

const store = configureStore({
  devTools: import.meta.env.DEV,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger),
  reducer: {
    professionalView: professionalReducer,
    search: searchReducer,
    ui: uiReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export default store
