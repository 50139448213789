import App from '~/components/app'
import '~/styles/pages/404.scss'

const NoMatch: React.FC<{ message?: string }> = ({
  message = 'Page not found!',
}) => {
  const location = useLocation()

  return (
    <App className='public nomatch' page='404'>
      <Container name='404'>
        <div className='nomatch__center'>
          <h1>{location.state?.message ?? message}</h1>
          <Link to='/'>Return Home</Link>
        </div>
      </Container>
    </App>
  )
}

export default NoMatch
