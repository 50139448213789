import { formatPhoneNumber, isEmptyString, pluralize } from '~/lib/utils/global'
import { scrollIntoView } from '~/lib/utils/scroll'
import type { Professional } from '~/store/modules/professional'

const ContactButton: React.FC<{
  className?: string
  toggleContactForm: Function
}> = ({ className = '', toggleContactForm }) => (
  <button
    className={`px-4 py-3 bg-green flex space-x-3 ${className}`}
    onClick={() => toggleContactForm()}
  >
    <IconMdiEmailVariant />
    <span className='flex-grow relative top-px'>Send a message</span>
  </button>
)

export const ProfessionalPublicView: React.FC<{
  professional: Professional
  toggleContactForm: Function
}> = ({ professional, toggleContactForm }) => {
  // const [reviewsVisible, setReviewsVisible] = useState(false)

  return (
    <div className='professional__public py-8 px-4 grid grid-flow-row-dense gap-12 sm:grid-cols-[1fr,18rem]'>
      <div className='flex space-x-4'>
        <img
          className='flex-shrink-0 object-cover rounded-full w-12 h-12 xs:(w-20 h-20) md:(w-24 h-24) lg:(w-32 h-32)'
          src={professional.url}
        />
        <div className='space-y-3'>
          <h1 className='mb-0'>
            <span>
              {professional.name} {professional.last_name}
              <span className='hidden'>, </span>
            </span>
            <div className='font-semibold'>
              <span className='block text-base'>
                {professional.professional_title}
              </span>
              {professional.practice !== null && (
                <span className='block text-xs'>
                  at {professional.practice}
                </span>
              )}
            </div>
          </h1>
          <div className='space-y-2 text-sm children:space-y-1'>
            <ul>
              {professional.degrees.map((v, i) => (
                <li key={i}>{v.name}</li>
              ))}
              {professional.certifications !== null &&
                professional.certifications.length > 0 &&
                professional.certifications.map((v, i) => (
                  <li key={i}>{v.name}</li>
                ))}
            </ul>
            {professional.positions_held.length > 0 && (
              <ul>
                {professional.positions_held.map((v, i) => (
                  <li key={i}>{v.name}</li>
                ))}
              </ul>
            )}
            <div>
              Speaks {professional.languages.map(v => v.name).join(' ,')}
            </div>
            <div>
              {professional.sexual_orientation} {professional.gender}
            </div>
          </div>
          {/* <div className='stack-x justify-start items-center space-x-2'>
              <ReviewRating score={professional.score} />
              {!!professional.reviews.length && (
                <button
                  onClick={e => setReviewsVisible(!reviewsVisible)}
                  className='naked'
                >{`Read ${professional.reviews.length} ${pluralize(
                  'review',
                  professional.reviews.length
                )}`}</button>
              )}
              <button className='blue naked'>
                {professional.reviews.length
                  ? 'Leave a review'
                  : 'Leave the first review'}
              </button>
              <Reviews
                className={reviewsVisible ? 'visible' : ''}
                reviews={professional.reviews}
              />
            </div> */}
        </div>
      </div>
      <div className='<sm:row-start-5 sm:row-span-3 space-y-4'>
        <div id='map-view' className='aspect-1/1'>
          <MapView
            professional={professional}
            className='shadow-md rounded-lg overflow-hidden children:children:children:children:rounded-xl children:children:children:children:overflow-hidden'
          />
        </div>
        <div className='space-y-3 text-center'>
          <ContactButton
            className='w-full rounded-lg'
            toggleContactForm={toggleContactForm}
          />
          {professional.show_phone && !isEmptyString(professional.phone) && (
            <p>
              Available by phone:{' '}
              <a
                className='font-bold !text-indigo whitespace-nowrap'
                href={`tel:${professional.phone}`}
              >
                {formatPhoneNumber(professional.phone)}
              </a>
            </p>
          )}
        </div>
        {/* <div className='professional__public__column__reviews'>
          <ReviewRating score={professional.score} />
          <div className='professional__public__column__reviews__header'>
            <h3 className='lighthouse-text-light noMargin'>
              Reviews ({professional.reviews.length})
            </h3>
            <button className='blue naked'>Leave a review</button>
          </div>
          <Reviews reviews={professional.reviews} />
        </div> */}
      </div>
      <div className='grid gap-8 sm:grid-cols-2 lg:grid-cols-3 children:(space-y-2 children:space-y-1)'>
        <div className='sm:col-span-2 lg:col-span-3'>
          <h2 className='!m-0 !font-bold text-xl'>Approach</h2>
          <p className='max-w-sm'>{professional.approach}</p>
        </div>
        <div className='sm:col-span-2 lg:col-span-3'>
          <h2 className='!m-0 !font-bold text-xl'>LGBTQ Experience</h2>
          <p className='max-w-sm'>{professional.lgbtq_experience}</p>
        </div>
        {!!professional.services?.length && (
          <div>
            <h2 className='!m-0 !font-bold text-xl'>Services</h2>
            <ul>
              {professional.services.map(x => (
                <li key={x.id}>{x.name}</li>
              ))}
            </ul>
          </div>
        )}
        {!!professional.specialties?.length && (
          <div className='lg:col-span-2'>
            <h2 className='!m-0 !font-bold text-xl'>Specialties</h2>
            <ul className='lg:(columns-2 gap-8)'>
              {professional.specialties.map(x => (
                <li key={x.id}>{x.name}</li>
              ))}
            </ul>
          </div>
        )}
        {(!!professional.price || !!professional.insurances?.length) && (
          <div className='sm:col-span-2 lg:col-span-3'>
            <h2 className='!m-0 !font-bold text-xl'>
              {professional.insurances?.length
                ? 'Insurance'
                : 'Out of pocket cost'}
            </h2>
            <ul className='sm:(columns-2 gap-8) lg:columns-3'>
              {professional.insurances.map(x => (
                <li key={x.id}>{x.name}</li>
              ))}
            </ul>
            <p className='font-semibold'>
              {!!professional.insurances?.length && 'Out of pocket cost: '}
              <span className='font-bold'>
                {professional.price}
                <span className='lighthouse-text-ultralight'>
                  {new Array(4 - professional.price.length).fill('$').join('')}
                </span>
              </span>
            </p>
          </div>
        )}
      </div>
      <div className='row-span-2 bg-white border border-solid border-gray border-opacity-5 p-4 rounded-xl shadow-md flex <sm:(-mt-6 row-start-2 flex-col space-y-2) sm:(col-span-2 space-x-4 children:basis-0 children:flex-grow)'>
        {professional.show_phone && !isEmptyString(professional.phone) && (
          <a
            className='space-x-2 flex items-center font-bold border border-solid border-gray border-opacity-10 rounded px-3 py-2 space-x-2'
            href={`tel:${professional.phone}`}
          >
            <IconMdiPhone />
            <span className='flex-grow text-center relative top-px'>
              Call now
            </span>
          </a>
        )}
        <ContactButton toggleContactForm={toggleContactForm} />
        <button
          className='sm:hidden space-x-2 flex items-center font-bold bg-transparent border border-solid border-gray border-opacity-10 rounded px-3 py-2 space-x-2'
          onClick={() => scrollIntoView(document.getElementById('map-view'))}
        >
          <IconMdiMap />
          <span className='flex-grow text-center relative top-px'>
            See on map
          </span>
        </button>
      </div>
    </div>
  )
}
