import type { FC } from 'react'
import { useEffect } from 'react'

import '~/styles/components/carousel.scss'

/**
 * Simple carousel that transitions through different background images
 */
export const Carousel: FC<{
  className?: string | undefined
  interval?: number
  images: string[]
}> = ({ className, images, interval: t = 5000 }) => {
  const [intervalId, setIntervalId] = useState<number>()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    setIntervalId(
      window.setInterval(() => setIndex((index + 1) % images.length), t)
    )

    return () => clearInterval(intervalId)
  }, [t])

  return (
    <div className={`carousel ${className}`}>
      {images.map((src, i) => (
        <div
          className={index === i ? 'visible' : undefined}
          style={{ backgroundImage: `url(${src})` }}
          key={i}
        />
      ))}
    </div>
  )
}
