import App from '~/components/app'
import '~/styles/pages/contact.scss'

const Contact: React.FC<{
  message: string
  location: any
}> = () => (
  <App className='public' page='contact'>
    <Container name='contact'>
      <iframe
        src='https://docs.google.com/forms/d/e/1FAIpQLSe1Ewn7OvIStOmtgkeRKmBohPgCkK4F6zHRwm1MfOod0m0syQ/viewform?embedded=true'
        scrolling='yes'
        onLoad={e => {
          const target = e.target as any
          target.style.height = `${target.contentWindow.document.documentElement.scrollHeight}px`
        }}
        frameBorder='0'
        marginHeight={0}
        marginWidth={0}
      >
        Loading…
      </iframe>
    </Container>
  </App>
)

export default Contact
