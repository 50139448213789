import type { FC } from 'react'
import type { RouteProps } from 'react-router'
import { Link } from 'react-router-dom'

export const Footer: FC<RouteProps & { className?: string }> = ({
  className = '',
}) => (
  <div className='bg-indigo text-white'>
    <div
      className={`max-w-lg mx-auto text-center p-4 <xs:text-sm xs:p-8 <md:space-y-8 md:stack-x ${className}`}
    >
      <span>
        &copy; {new Date().getFullYear()} Lighthouse LGBT Inc. All rights
        reserved.
      </span>
      <div className='stack-x space-x-4 children:(font-bold cursor-pointer)'>
        <Link to='/contact'>Contact Us</Link>
        <Link to='/terms'>Terms of Service</Link>
        <Link to='/privacy'>Privacy Policy</Link>
      </div>
    </div>
  </div>
)
