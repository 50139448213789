import type { ChangeEvent, FC, KeyboardEvent } from 'react'

import type { ConnectedProps } from '~/connect'
import { isEmptyString } from '~/lib/utils/global'
import { constructUrl } from '~/store/modules/search'
import { screenWidths } from '~/styles'

const _SearchBar: FC<
  ConnectedProps & {
    performSearch?: Function
    className?: string
    context?: string
  }
> = ({
  className = '',
  context,
  fetchAutocompleteData,
  fetchSearchResults,
  search,
  updateQueryParam,
  performSearch,
}) => {
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [suggestionIndex, setSuggestionIndex] = useState<number | null>(null)
  const navigate = useNavigate()

  // const _performSearch =
  //   performSearch ||
  //   (() => {
  //     navigate({
  //       pathname: '/search',
  //       search: constructUrl(search),
  //     })

  //     fetchSearchResults(null, context)
  //   })

  const _performSearch = () => {
    navigate({
      pathname: '/search',
      search: constructUrl(search),
    })

    fetchSearchResults(null, context)
  }

  useEffect(() => {
    fetchAutocompleteData()
  }, [])

  const applySuggestion = async (i: number) => {
    setSuggestionIndex(null)
    setSuggestions([])
    await updateQueryParam('query', suggestions[i])
  }

  const onInputKeyDown = async (
    field: 'query' | 'zip',
    { key }: KeyboardEvent
  ) => {
    if (field === 'query' && suggestions.length) {
      if (key === 'ArrowUp')
        setSuggestionIndex(
          suggestionIndex == null
            ? 0
            : suggestionIndex
            ? suggestionIndex - 1
            : null
        )
      if (key === 'ArrowDown')
        setSuggestionIndex(
          suggestionIndex == null
            ? 0
            : Math.min(suggestions.length - 1, suggestionIndex + 1)
        )
      if (
        (key === 'ArrowRight' || key === 'Enter' || key === 'Tab') &&
        suggestionIndex !== null
      )
        applySuggestion(suggestionIndex)
    }
    if (key === 'Enter') {
      _performSearch()
      setSuggestions([])
    }
  }

  const onInputChange = async (
    field: string,
    { target: { value } }: ChangeEvent<HTMLInputElement>
  ) => {
    await updateQueryParam(field, value)

    if (field === 'query')
      setSuggestions(
        value.length
          ? search.autoCompleteItems.filter(v =>
              v.toLowerCase().includes(value.toLowerCase())
            )
          : []
      )
  }

  const onSuggestionClick = async (value: string) => {
    await updateQueryParam('query', value)
    setSuggestions([])

    if (!isEmptyString(search.zip)) _performSearch()
  }

  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const { sm } = screenWidths

  const c = useMemo(
    () =>
      `font-semibold text-gray-800 ${
        width >= sm ? 'stack-x space-x-8' : 'stack-y space-y-4'
      } ${className}`,
    [className, width]
  )

  return (
    <div ref={ref} className={c}>
      {/* <div className='stack-x space-x-2'>
          <button className='btn-md bg-white' disabled>
            <span className='text-black'>You</span>
            <IconMdiAccountEdit className='text-green' />
          </button>
          <span>are looking for a</span>
        </div> */}
      <span className='opacity-75 text-sm font-normal'>
        Find providers and specialists:
      </span>
      <div className='flex-grow stack-x justify-center space-x-2'>
        <div className='flex-grow relative'>
          <input
            className='w-full truncate'
            placeholder='Therapist, primary care, dentist...'
            type='text'
            value={search.query}
            // onBlur={(e) => this.onInputChange('query', e)}
            onChange={e => onInputChange('query', e)}
            onKeyDown={e => onInputKeyDown('query', e)}
          />
          <ul className='absolute top-full inset-x-0 z-10 rounded-b-lg shadow-lg overflow-hidden bg-white text-left'>
            {suggestions.slice(0, 5).map((v, i) => (
              <li
                className={`p-4 cursor-pointer transition hover:bg-gray-100 ${
                  suggestionIndex === i ? 'bg-gray-100' : ''
                }`}
                key={v}
                onClick={() => onSuggestionClick(v)}
              >
                {v}
              </li>
            ))}
          </ul>
        </div>
        <span>near</span>
        <input
          className='max-w-12'
          type='text'
          maxLength={5}
          placeholder='ZIP'
          value={search.zip}
          // onBlur={(e) => _performSearch()}
          onChange={e => onInputChange('zip', e)}
          onKeyDown={e => onInputKeyDown('zip', e)}
        />
      </div>
      <button className='btn-md' onClick={() => _performSearch()}>
        <span>Search</span>
        <IconMdiMagnify />
      </button>
    </div>
  )
}

export const SearchBar = connect()(_SearchBar)
