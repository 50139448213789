import App from '~/components/app'
import '~/styles/pages/terms.scss'
// const htmlContent = require('~/components/terms/terms.html');

const Privacy: React.FC<{ message?: string }> = () => (
  <App className='public nomatch' page='404'>
    <Container name='privacy' innerClass='navWidth'>
      <div className='privacy <sm:p-4 <lg:p-8 lg:py-8 children:(space-y-4 children:space-y-2)'>
        <h2>Privacy Policy</h2>

        <p>Effective Date: Jan 1, 2022</p>

        <p>
          Our company’s most important value is ‘You First’, which means that we
          are committed to protecting your privacy and we take great care with
          your personal information. This policy will help you understand how we
          use and protect your data. If you have any questions, feel free to
          contact us at support@lighthouse.lgbt. You can also learn more at
          lighthouse.lgbt/about. Thank you for choosing Lighthouse.
        </p>

        <p>
          <strong>1. Introduction</strong>
        </p>
        <p>
          Lighthouse LGBT Inc. (<strong>“us,”“we,” or “Lighthouse”</strong>) is
          committed to respecting the privacy rights of our customers, visitors,
          and other users of Lighthouse.lgbt (the <strong>“Site”</strong>) and
          related websites, applications, services and mobile applications
          provided by Lighthouse and on/in which this Privacy Policy is posted
          or referenced (collectively, the <strong>“Services”</strong>). We
          created this Privacy Policy (<strong>“Privacy Policy”</strong>) to
          give you confidence as you use the Services and to demonstrate our
          commitment to the protection of privacy. This Privacy Policy is only
          applicable to the Services. This Privacy Policy does not apply to any
          other website or digital service that you may be able to access
          through the Services or any website or digital services of
          Lighthouse’s business partners, each of which may have data
          collection, storage and use practices and policies that may differ
          from this Privacy Policy. Your use of the Services is governed by this
          Privacy Policy and the Agreement (as the term “Agreement” is defined
          in our Terms of Use). Any capitalized term used but not defined in
          this Privacy Policy shall have the meaning in the Agreement.
        </p>
        <p>
          BY USING THE SERVICES, YOU AGREE TO THE PRACTICES AND POLICIES
          OUTLINED IN THIS PRIVACY POLICY AND YOU HEREBY CONSENT TO THE
          COLLECTION, USE, AND SHARING OF YOUR INFORMATION AS DESCRIBED IN THIS
          PRIVACY POLICY. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY, YOU
          CANNOT USE THE SERVICES. IF YOU USE THE SERVICES ON BEHALF OF SOMEONE
          ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR EMPLOYER), YOU
          REPRESENT THAT YOU ARE AUTHORIZED BY SUCH INDIVIDUAL OR ENTITY TO
          ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF.
        </p>

        <p>
          <strong>2. Information We Collect</strong>
        </p>
        <p>
          <strong>2.1 Personal Information Generally</strong>
        </p>
        <p>
          Some of the Services require us to learn more about you so that we can
          best meet your needs. When you access the Services, we may ask you to
          voluntarily provide us certain information that personally identifies
          you or could be used to personally identify you (
          <strong>“Personal Information”</strong>). Personal Information
          includes (but is not limited to) the following categories of
          information: (1) contact data (such as your e-mail address and phone
          number); (2) demographic data (such as your gender, your date of birth
          and your zip code); (3) insurance data (such as your insurance
          carrier, insurance plan, member ID, group ID and payer ID); (4)
          medical data (such as the doctors, dentists or other healthcare
          specialists, professionals, providers, or organizations (collectively,
          <strong>“Healthcare Providers”</strong>) you have visited, your
          reasons for visit, your dates of visit, your medical history, and
          other medical and health information you choose to share with us); and
          (5) other identifying information that you voluntarily choose to
          provide to us, including without limitation unique identifiers such as
          passwords, and Personal Information in emails or letters that you send
          to us. We may also collect additional information, which may be
          Personal Information, as otherwise described to you at the point of
          collection or pursuant to your consent. You may still access and use
          some of the Services if you choose not to provide us with any Personal
          Information, but features of the Services that require your Personal
          Information will not be accessible to you.
        </p>

        <p>
          <strong>2.2 Billing, Collection and Payment Information</strong>
        </p>
        <p>
          When you make a payment through our Services (as further described in
          and subject to other provisions of the Agreement), your payment card
          information is processed by our payment processing partner, such as
          Stripe{' '}
          <a href='https://stripe.com' target='_blank' rel='noreferrer'>
            {' '}
            rel="noreferrer" (https://stripe.com)
          </a>
          . Our payment processing partner collects your voluntarily provided
          payment card information necessary to process your payment. Such
          partner’s use and storage of information it collects is governed by
          its applicable terms of service and privacy policy. The information we
          store includes your payment card type and the last four digits of the
          payment card.
        </p>

        <p>
          <strong>2.3 Traffic Data</strong>
        </p>
        <p>
          We also may automatically collect certain data when you use the
          Services, such as (1) IP address; (2) domain server; (3) type of
          device(s) used to access the Services; (4) web browser(s) used to
          access the Services; (5) referring webpage or other source through
          which you accessed the Services; (6) geolocation information; and (7)
          other statistics and information associated with the interaction
          between your browser or device and the Services (collectively{' '}
          <strong>“Traffic Data”</strong>). Depending on applicable law, some
          Traffic Data may be Personal Information.
        </p>

        <p>
          <strong>2.4 HIPAA and PHI</strong>
        </p>
        <p>
          Under a federal law called the Health Insurance Portability and
          Accountability Act (<strong>“HIPAA”</strong>), some demographic,
          health and/or health-related information that Lighthouse collects as
          part of providing the Services may be considered “protected health
          information” or <strong>“PHI.”</strong> Specifically, when Lighthouse
          receives identifiable information about you from or on behalf of your
          Healthcare Providers, this information is considered PHI.
        </p>
        <p>
          HIPAA provides specific protections for the privacy and security of
          PHI and restricts how PHI is used and disclosed. Lighthouse may only
          use and disclose your PHI in the ways permitted by your Healthcare
          Provider(s).
        </p>

        <p>
          <strong>3. How We Collect Information</strong>
        </p>
        <p>
          We collect information (including Personal Information and Traffic
          Data) when you use and interact with the Services, and in some cases
          from third party sources. Such means of collection include:
        </p>

        <li>
          When you use the Services’ interactive tools and services, such as
          searching for Healthcare Providers and completing contact forms (
          <strong>“Medical History Forms”</strong>) prior to Healthcare Provider
          appointments;
        </li>
        <li>
          When you voluntarily provide information in free-form text boxes
          through the Services or through responses to surveys, questionnaires
          and the like;
        </li>
        <li>
          If you download and install certain applications and software we make
          available, we may receive and collect information transmitted from
          your computing device for the purpose of providing you the relevant
          Services, such as information regarding when you are logged on and
          available to receive updates or alert notices;
        </li>
        <li>
          If you download our mobile application or use a location-enabled
          browser, we may receive information about your location and mobile
          device, as applicable;
        </li>
        <li>
          Through cookies, web beacons, website analytics services and other
          tracking technology (collectively, “Tracking Tools”), as described
          below; and
        </li>
        <li>
          When you use the “Contact Us” function on the Site, send us an email
          or otherwise contact us.
        </li>
        <br />

        <p>
          <strong>4. Tracking Tools and “Do Not Track”</strong>
        </p>
        <p>
          <strong>4.1. Tracking Tools</strong>
        </p>
        <p>
          We may use tools outlined below in order to provide our Services to,
          advertise to, and to better understand users.
        </p>
        <li>
          <strong>Cookies:</strong> “Cookies” are small computer files
          transferred to your computing device that contain information such as
          user ID, user preferences, lists of pages visited and activities
          conducted while using the Services. We use Cookies to improve or
          tailor the Services or customize advertisements by tracking navigation
          habits, measuring performance, storing authentication status so
          re-entering credentials is not required, customizing user experiences
          with the Services and for analytics and fraud prevention. For more
          information on cookies, visit{' '}
          <a
            href='http://www.allaboutcookies.org'
            target='_blank'
            rel='noreferrer'
          >
            {' '}
            rel="noreferrer" http://www.allaboutcookies.org.
          </a>
        </li>
        <li>
          <strong>Web Beacons:</strong> “Web Beacons” (a.k.a. clear GIFs or
          pixel tags) are tiny graphic image files embedded in a web page or
          email that may be used to collect information about the use of our
          Services, the websites of selected advertisers and the emails, special
          promotions or newsletters that we send. The information collected by
          Web Beacons allows us to analyze how many people are using the
          Services, using selected publishers’ websites or opening emails, and
          for what purpose, and also allows us to enhance our interest-based
          advertising (discussed further below).
        </li>
        <li>
          <strong>Website Analytics:</strong> We may use third-party website
          analytics services in connection with the Services, including, for
          example, to register mouse clicks, mouse movements, scrolling activity
          and text typed into the Site. We use the information collected from
          these services to help make the Services easier to use and as
          otherwise set forth in Section 5 (Use of Information).These website
          analytics services generally do not collect Personal Information
          unless you voluntarily provide it and generally do not track your
          browsing habits across websites that do not use their services.
        </li>
        <li>
          <strong>Mobile Device Identifiers:</strong> As with other Tracking
          Tools, mobile device identifiers help Lighthouse learn more about our
          users’ demographics and internet behaviors. Mobile device identifiers
          are data stored on mobile devices that may track mobile device and
          data and activities occurring on and through it, as well as the
          applications installed on it. Mobile device identifiers enable
          collection of Personal Information (such as media access control,
          address and location) and Traffic Data.
        </li>
        <li>
          <strong>Cross Device Matching:</strong> To determine if users have
          interacted with content across multiple devices and to match such
          devices, we may work with partners who analyze device activity data
          and/or rely on your information (including demographic, geographic and
          interest-based data). To supplement this analysis, we may also provide
          de-identified data these partners. Based on this data, we may then
          display targeted advertisements across devices that we believe are
          associated or use this data to further analyze usage of Services
          across devices.
        </li>
        <br />

        <p>
          <strong>4.2. Tracking Tools</strong>
        </p>
        <p>
          We may use a type of advertising commonly known as interest-based or
          online behavioral advertising. This means that some of our partners
          use Tracking Tools to display Lighthouse ads on other websites or
          services based on information about your use of the Services or your
          interests (as inferred from online activity). Such partners may
          include third-party service providers, advertisers, advertising
          networks or platforms, and agencies. Other Tracking Tools used by our
          partners may collect information when you use the Services, such as IP
          address, mobile device ID, operating system, browser, web page
          interactions, geographic location and demographic information, such as
          gender and age range. These Tracking Tools help Lighthouse learn more
          about our users’ demographics and internet behaviors.
        </p>

        <p>
          <strong>
            4.3. Options for Opting out of Cookies and Mobile Device Identifiers
          </strong>
        </p>
        <p>
          Some web browsers (including some mobile web browsers) allow you to
          reject Cookies or to alert you when a Cookie is placed on your
          computer, tablet or mobile device. You may be able to reject mobile
          device identifiers by activating the appropriate setting on your
          mobile device. Although you are not required to accept Lighthouse’s
          Cookies or mobile device identifiers, if you block or reject them, you
          may not have access to all features available through the Services.
        </p>
        <p>
          You may opt out of receiving certain Cookies and certain trackers by
          visiting the Network Advertising Initiative (NAI){' '}
          <a
            href='http://www.networkadvertising.org/choices/'
            target='_blank'
            rel='noreferrer'
          >
            {' '}
            rel="noreferrer" opt out page
          </a>{' '}
          or the Digital Advertising Alliance (DAA){' '}
          <a
            href='http://www.aboutads.info/choices/'
            target='_blank'
            rel='noreferrer'
          >
            consumer opt-out page
          </a>{' '}
          or by installing the DAA’s AppChoice app (for{' '}
          <a
            href='https://itunes.apple.com/us/app/appchoices/id894822870?mt=8'
            target='_blank'
            rel='noreferrer'
          >
            iOS
          </a>
          ; for{' '}
          <a
            href='https://play.google.com/store/apps/details?id=com.DAA.appchoices&amp;hl=en'
            target='_blank'
            rel='noreferrer'
          >
            {' '}
            rel="noreferrer" Android
          </a>
          ) on your mobile computing device. When you use these opt-out
          features, an “opt-out” Cookie will be placed on your computer, tablet
          or mobile computing device indicating that you do not want to receive
          interest-based advertising from NAI or DAA member companies. If you
          delete Cookies on your computer, tablet or mobile computing device,
          you may need to opt out again. For information about how to opt out of
          interest-based advertising on mobile devices, please visit{' '}
          <a
            href='http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device'
            target='_blank'
            rel='noreferrer'
          >
            http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device
          </a>
          . You will need to opt out of each browser and device for which you
          desire to apply these opt-out features.
        </p>
        <p>
          Please note that even after opting out of interest-based advertising,
          you may still see Lighthouse advertisements that are not
          interest-based (i.e., not targeted toward you). Also, opting out does
          not mean that Lighthouse is no longer using Tracking Tools —
          Lighthouse still may collect information about your use of the
          Services even after you have opted out of interest-based
          advertisements and may still serve advertisements to you via the
          Services based on information it collects via the Services.
        </p>

        <p>
          <strong>
            4.4. How Lighthouse Responds to Browser “Do Not Track” (DNT) Signals
          </strong>
        </p>
        <p>
          Some web browsers (including Safari, Internet Explorer, Firefox and
          Chrome) incorporate a “Do Not Track” (DNT) or similar feature that
          signals to websites that a visitor does not want to have his/her
          online activity and behavior tracked. If a website operator elects to
          respond to a particular DNT signal, the website operator may refrain
          from collecting certain Personal Information about the browser’s user.
          Not all browsers offer a DNT option and there is currently no industry
          consensus as to what constitutes a DNT signal. For these reasons, many
          website operators, including Lighthouse, do not proactively respond to
          DNT signals. For more information about DNT signals, visit{' '}
          <a href='http://allaboutdnt.com' target='_blank' rel='noreferrer'>
            {' '}
            rel="noreferrer" http://allaboutdnt.com.
          </a>
        </p>

        <p>
          <strong>5. Use of Information</strong>
        </p>
        <p>
          We may use information that is neither Personal Information nor PHI
          (including non-PHI Personal Information that has been de-identified
          and/or aggregated) to better understand who uses Lighthouse and how we
          can deliver a better healthcare experience (for example, testing
          different kinds of emails has helped us understand when and how
          patients prefer to get Wellness Reminders for preventive care), or
          otherwise at our discretion.
        </p>
        <p>
          We use information, including Personal Information, to provide the
          Services and to help improve the Services, to develop new services,
          and to advertise (for example, to display Lighthouse ads on other
          websites). Such use may include:
        </p>

        <li>
          Providing you with the products, services and information you request;
          Responding to correspondence that we receive from you;
        </li>
        <li>
          Contacting you when necessary or requested, including to remind you of
          an upcoming appointment;
        </li>
        <li>
          Providing, maintaining, administering or expanding the Services,
          performing business analyses, or for other internal purposes to
          support, improve or enhance our business, the Services, and other
          products and services we offer;
        </li>
        <li>
          Customizing or tailoring your experience of the Services, which may
          include sending customized messages or showing you Sponsored Results;
        </li>
        <li>
          Notifying you about certain resources, Healthcare Providers or
          services we think you may be interested in learning more about;
        </li>
        <li>
          Sending you information about Lighthouse or our products or Services;
        </li>
        <li>
          Sending emails and other communications that display content that we
          think will interest you and according to your preferences;
        </li>
        <li>
          Combining information received from third parties with information
          that we have from or about you and use the combined information for
          any of the purposes described in this Privacy Policy;
        </li>
        <li>
          Showing you advertisements, including interest-based or online
          behavioral advertising;
        </li>
        <li>
          Using statistical information that we collect in any way permitted by
          law, including from third parties in connection with their commercial
          and marketing efforts; and
        </li>
        <li>
          Fulfilling our legally required obligations, such as preventing,
          detecting and investigating security incidents and potentially illegal
          or prohibited activities.
        </li>
        <br />

        <p>
          <strong>6. Disclosure of Information</strong>
        </p>
        <p>
          In certain circumstances, and in order to perform the Services, we may
          disclose certain information that we collect from you:
        </p>

        <li>
          We may share your Personal Information with Healthcare Providers with
          whom you choose to schedule through the Services. For example, if you
          complete a Medical History Form using the Services in advance of an
          appointment and elect to share it with your selected Healthcare
          Provider, we may share your Medical History Form with such selected
          Healthcare Providers.
        </li>
        <li>
          Provided that you choose to use the applicable Services, we may share
          your Personal Information with your Healthcare Providers to enable
          them to refer you to and make appointments with other Healthcare
          Providers on your behalf or to perform analyses on potential health
          issues or treatments.
        </li>
        <li>
          We may share your Personal Information with Healthcare Providers in
          the event of an emergency.
        </li>
        <li>
          To make your information more securely and easily accessible to your
          Healthcare Providers, we may share your Personal Information with
          Health Information Exchanges and related organizations that collect
          and organize your information (such as Regional Health Information
          Organizations). The goal of such organizations is to facilitate access
          to health information to improve the safety, quality, and efficiency
          of patient-centered care. More information on Health Information
          Exchanges can be found{' '}
          <a
            href='https://www.healthit.gov/HIE'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>{' '}
          and{' '}
          <a
            href='https://www.healthit.gov/providers-professionals/health-information-exchange/what-hie'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .
        </li>
        <li>
          We do not sell email addresses to third parties. We may share your
          Personal Information with our partners to customize or display our
          advertising.
        </li>
        <li>
          We may share your Personal Information and Traffic Data with our
          partners who perform operational services (such as hosting, billing,
          fulfillment, data storage, security, insurance verification, Website
          analytics, or ad serving) and/or who make certain services, features
          or functionality available to our users.
        </li>
        <li>
          We may share your Personal Information with the insurance provider you
          identify to us (and via our partners) to determine eligibility and
          cost-sharing obligations, and otherwise obtain benefit plan
          information on your behalf.
        </li>
        <li>
          We may transfer your information to another company in connection with
          a merger, sale, acquisition or other change of ownership or control by
          or of Lighthouse (whether in whole or in part). Should one of these
          events occur, we will make reasonable efforts to notify you before
          your information becomes subject to different privacy and security
          policies and practices.
        </li>
        <br />
        <p>
          We also may need to disclose your Personal Information or any other
          information we collect about you if we determine in good faith that
          such disclosure is needed to: (1) comply with or fulfill our
          obligations under applicable law, regulation, court order or other
          legal process; (2) protect the rights, property or safety of you,
          Lighthouse or another party; (3) enforce the Agreement or other
          agreements with you; or (4) respond to claims that any posting or
          other content violates third-party rights.
        </p>
        <p>
          We may disclose information that is neither Personal Information nor
          PHI (including non-PHI Personal Information that has been
          de-identified and/or aggregated) at our discretion.
        </p>

        <p>
          <strong>7. Public Information</strong>
        </p>
        <p>
          You agree that any information that you may reveal in a review posting
          or online discussion or social media channel or forum is intentionally
          open to the public and is not in any way private. We recommend that
          you carefully consider whether to disclose any Personal Information in
          any public posting or forum. What you have written may be seen and/or
          collected by third parties and may be used by others in ways we are
          unable to control or predict.
        </p>

        <p>
          <strong>8. Storage and Security of Information</strong>
        </p>
        <p>
          The security of your Personal Information is important to us. We
          endeavor to follow generally accepted industry standards to protect
          the Personal Information submitted to us, both during transmission and
          in storage. For example, when you enter sensitive information on our
          Site, we encrypt that information using Secure Socket Layer (SSL)
          technology.
        </p>
        <p>
          We store and process your information on our servers in the United
          States and abroad. We maintain industry standard backup and archival
          systems.
        </p>
        <p>
          Although we make good faith efforts to store Personal Information in a
          secure operating environment that is not open to the public, we do not
          and cannot guarantee the security of your Personal Information. If at
          any time during or after our relationship we believe that the security
          of your Personal Information may have been compromised, we may seek to
          notify you of that development. If a notification is appropriate, we
          will endeavor to notify you as promptly as possible under the
          circumstances. If we have your e-mail address, we may notify you by
          e-mail to the most recent e-mail address you have provided us in your
          account profile. Please keep your e-mail address in your account up to
          date. You can update that e-mail address anytime in your account
          profile. If you receive a notice from us, you can print it to retain a
          copy of it. To receive these notices, you must check your e-mail
          account using your computer or mobile device and email application
          software.{' '}
          <strong>
            You consent to our use of e-mail as a means of such notification. If
            you prefer for us to use the U.S. Postal Service to notify you in
            this situation, please e-mail us at
          </strong>{' '}
          <a href='mailto:support@lighthouse.lgbt'>support@lighthouse.lgbt</a>.
          Please include your address when you submit your request. You can make
          this election any time, and it will apply to notifications we make
          after a reasonable time thereafter for us to process your request. You
          may also use this e-mail address to request a print copy, at no
          charge, of an electronic notice we have sent to you regarding a
          compromise of your Personal Information.
        </p>

        <p>
          <strong>
            9. Controlling Your Personal Information &amp; Notifications
          </strong>
        </p>
        <p>
          If you are a registered user of the Services, you can modify certain
          Personal Information or account information by logging in and
          accessing your account. If you wish to close your account, please
          email us at{' '}
          <a href='mailto:support@lighthouse.lgbt'>support@lighthouse.lgbt</a>.
          Lighthouse will delete your account and the related information at
          your request as soon as reasonably possible. Please note, however,
          that Lighthouse reserves the right to retain information from closed
          accounts, including to comply with law, prevent fraud, resolve
          disputes, enforce the Agreement and take other actions permitted by
          law. You must promptly notify us if any of your account data is lost,
          stolen or used without permission.
        </p>

        <p>
          <strong>
            10. Information Provided on Behalf of Children and Others
          </strong>
        </p>
        <p>
          The Services are not intended for use by children and children are
          prohibited from using the Services. Lighthouse does not knowingly
          collect any information from children, nor are the Services directed
          to children. By accessing, using and/or submitting information to or
          through the Services, you represent that you are not younger than age
          thirteen (13). If we learn that we have received any information
          directly from a child under age thirteen (13) without his/her parent’s
          written consent, we will use that information only to respond directly
          to that child (or his/her parent or legal guardian) to inform the
          child that he/she cannot use the Services, and we will subsequently
          delete that information.
        </p>
        <p>
          If you are between age thirteen (13) and the age of majority in your
          place of residence, you may use the Services only with the consent
          ofor under the supervision of your parent or legal guardian. If you
          are a parent or legal guardian of a minor child, you may, in
          compliance with the Agreement, use the Services on behalf of such
          minor child. Any information that you provide us while using the
          Services on behalf of your minor child will be treated as Personal
          Information as otherwise provided herein.
        </p>
        <p>
          If you use the Services on behalf of another person, regardless of
          age, you agree that Lighthouse may contact you for any communication
          made in connection with providing the Services or any legally required
          communications. You further agree to forward or share any such
          communication with any person for whom you are using the Services on
          behalf.
        </p>

        <p>
          <strong>11. Other Websites</strong>
        </p>
        <p>
          The Services contain links to or embedded content from third party
          website. A link to or embedded content from a non-Lighthouse website
          does not mean that we endorse that website, the quality or accuracy of
          information presented on the non-Lighthouse website or the persons or
          entities associated with the non-Lighthouse website. If you decide to
          visit a third party website, you are subject to the privacy policy of
          the third party website as applicable and we are not responsible for
          the policies and practices of the third party website. We encourage
          you to ask questions before you disclose your information to others.
        </p>
        <p>
          You may have arrived at the Services from, or began your use of the
          Services at, a third party website, including a third party website
          that links to Lighthouse or embeds Lighthouse content. The presence of
          such links or content on third party websites does not mean that we
          endorse that website, the quality or accuracy of information presented
          on the non-Lighthouse website or the persons or entities associated
          with the non-Lighthouse website. You may be subject to the privacy
          policy of the third party website as applicable and we are not
          responsible for the policies and practices of the third party
          websites. In addition, the policies and practices of third parties do
          not apply to your information, including Personal Information,
          obtained pursuant to this Privacy Policy.
        </p>

        <p>
          <strong>12. Updates and Changes to Privacy Policy</strong>
        </p>
        <p>
          The effective date of this Privacy Policy is set forth at the top of
          this webpage. We will notify you of any material change by posting
          notice on this webpage. Your continued use of the Services after the
          effective date constitutes your acceptance of the amended Privacy
          Policy. We encourage you to periodically review this page for the
          latest information on our privacy practices. Any amended Privacy
          Policy supersedes all previous versions. IF YOU DO NOT AGREE TO FUTURE
          CHANGES TO THIS PRIVACY POLICY, YOU MUST STOP USING THE SERVICES AFTER
          THE EFFECTIVE DATE OF SUCH CHANGES.
        </p>

        <p>
          <strong>13. Contacts</strong>
        </p>
        <p>
          If you have any comments, concerns or questions about this Privacy
          Policy, please contact us at{' '}
          <a href='mailto:support@lighthouse.lgbt'>support@lighthouse.lgbt</a>.
        </p>
      </div>
    </Container>
  </App>
)

export default Privacy
