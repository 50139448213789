import Snackbar from '@material-ui/core/Snackbar'

import type { ConnectedProps } from '~/connect'
import { useScript } from '~/hooks'
import '~/styles/global/index.scss'

const App: React.FC<
  {
    script?: string
    children?: React.ReactNode
    className?: string
    background?: string
    scriptAdded?: Function
    title?: string
    page?: string
    footerClass?: string
    outer?: JSX.Element[] | JSX.Element | any
  } & ConnectedProps
> = ({
  children,
  className = '',
  footerClass,
  outer,
  page,
  script,
  scriptAdded,
  title,
  ui: { showSnackbar, snackbarClass, snackbarText },
}) => {
  const analytics = useAnalytics()

  useEffect(() => {
    document.title = title
      ? `${title} | Lighthouse`
      : `Find LGBTQIA+ Doctors and Therapists | Lighthouse`

    if (page) analytics?.page({ title: page })
  }, [])

  useScript(script, () => scriptAdded(script))

  // /**
  //  * Because the body background changes from page to page
  //  * we're updating it here based on how the parent component decides to
  //  * pass the `background` prop
  //  * @param  {Boolean} background To include a background or not
  //  */
  // const updateBackground = (className = 'grayBg') => {
  //   document.body.className = className
  // }

  return (
    <div className={`app ${className}`}>
      <div className='app__inner'>
        {children}
        {/* <div className="push"></div> */}
      </div>
      <Snackbar
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        className={`lighthouse-snackbar ${snackbarClass}`}
        open={showSnackbar}
        autoHideDuration={3000}
        message={snackbarText}
      />
      {outer}
      <Footer className={footerClass} />
    </div>
  )
}

export default connect()(App)
