import type { ConnectedProps } from '~/connect'
// import ProtectedCmp from '~/pages/protected'

const ProviderRoute: React.FC<ConnectedProps> = ({
  fetchDetailProfessional,
}) => {
  const [providerNotFound, setProviderNotFound] = useState(false)

  const match = useMatch('/provider/:id')
  const navigate = useNavigate()

  useEffect(() => {
    if (isNaN(parseInt(match?.params.id ?? ''))) navigate('/')
    else
      tc(
        () => fetchDetailProfessional(match?.params.id),
        () => setProviderNotFound(true)
      )
  }, [])

  return providerNotFound ? (
    <ErrorView message='We unfortunately could not find this provider.' />
  ) : (
    <ProviderView />
  )
}

export default connect()(ProviderRoute)
