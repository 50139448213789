import axios from 'axios'

import { get } from './api'

const instance = axios.create({
  baseURL: 'https://blog.lighthouse.lgbt',
  timeout: 6000,
})

/**
 * Returns four most recent blog posts
 */
export const getBlogPosts = () =>
  get(
    `/wp-json/wp/v2/posts?_embed&orderby=date&order=desc&per_page=4`,
    null,
    instance
  )

/**
 * Returns post matching slug. Returns in an array unfortunately, so just take the first element out of it.
 * @param slug
 */
export const getBlogPost = (slug: string) =>
  get(`/wp-json/wp/v2/posts?_embed&slug=${slug}`, null, instance)
