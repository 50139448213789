import type { FC } from 'react'

import '~/styles/global/loader.scss'

/**
 * Standard Material Google Cmp.
 */
export const Loader: FC<{
  className?: string
  dark?: boolean
}> = ({ className, dark }) => (
  <div className={`loader ${className || ''}`}>
    <svg className='circular' viewBox='25 25 50 50'>
      <circle
        className='path'
        cx='50'
        cy='50'
        r='20'
        fill='none'
        strokeWidth='4'
        strokeMiterlimit='10'
      />
    </svg>
  </div>
)
