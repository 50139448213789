import { render } from 'react-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'react-redux'

import store from './store'

import routes from '~react-pages'

import 'virtual:windi.css'
import '~/styles/main.css'

// import "~/assets/apple_touch_icon.png";
// import "~/assets/favicon-32x32.png";
// import "~/assets/favicon-16x16.png";
// import "~/assets/safari-pinned-tab.svg";
// import "~/assets/favicon-32x32.png";

const App = () => {
  return useRoutes(routes)
}

render(
  <GoogleReCaptchaProvider
    reCaptchaKey='6LdYktkeAAAAAKUjJ2Tz_WKtLiWICSSARK5rdFDr'
    useRecaptchaNet
    scriptProps={{
      defer: true,
      // nonce: undefined,
    }}
  >
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
)
