import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import type { ConnectedProps } from '~/connect'
import { contactProfessional } from '~/lib/professional'
import { isEmptyString } from '~/lib/utils/global'
import type { Professional } from '~/store/modules/professional'
import '~/styles/components/contactForm.scss'

/**
 * Component for Sending Inquiry to professionals
 */
const _ContactForm: React.FC<
  {
    className?: string
    professional: Professional | null
    visible: boolean
  } & ConnectedProps
> = ({
  className = '',
  professional,
  toggleContactModal,
  toggleSnackbar,
  ui: { contactModal, contactModalClass = '' },
  // visible,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    message: '',
    phone: '',
    pronouns: '',
    voicemail: false,
  })
  const [errors, setErrors] = useState({
    email: false,
    firstname: false,
    lastname: false,
    message: false,
    phone: false,
    pronouns: false,
    voicemail: false,
  })

  const analytics = useAnalytics()

  const submit = async () => {
    if (!professional) return

    const errorEntries = Object.entries(formData).map(
      ([k, v]) => [k, isEmptyString(v)] as const
    )

    setErrors(
      errorEntries.reduce((acc, [k, v]) => ({ ...acc, [k]: v }), errors)
    )

    if (errorEntries.filter(([_, v]) => v).length) {
      toggleSnackbar('Please fill in the remaining fields', true)
      return
    }

    // captcha

    setLoading(true)

    const [data, e] = await tc(async () => {
      if (!executeRecaptcha) throw new Error('No recaptcha')
      const recaptchaToken = await executeRecaptcha()

      return await contactProfessional({
        initial_message: formData.message,
        patient_email: formData.email,
        patient_first_name: formData.firstname,
        patient_last_name: formData.lastname,
        patient_phone: formData.phone,
        patient_pronouns: formData.pronouns,
        patient_voicemail: formData.voicemail,
        professional_id: professional.id,
        recaptchaToken,
      })
    })

    if (e) {
      toggleSnackbar(
        'Something went wrong on our end! Please contact support@lighthouse.lgbt for help.',
        true
      )

      setLoading(false)
      return
    }

    if (data.status === 'failure') {
      const { message } = data

      if (message.includes('patient_phone')) {
        toggleSnackbar(
          "The phone number you've entered is unfortunately not valid.",
          true
        )
        setErrors({
          ...errors,
          phone: true,
        })
      } else if (message.includes('patient_email')) {
        toggleSnackbar(
          "The email you've entered is unfortunately not valid.",
          true
        )
        setErrors({
          ...errors,
          email: true,
        })
      } else throw new Error('Unknown error')

      setLoading(false)
      return
    }

    setSuccess(true)
    setLoading(false)
    analytics?.track('Contact form submited', {
      professional_id: professional.id,
    })
  }

  const updateField = <K extends keyof typeof formData>(
    field: K,
    value: typeof formData[K]
  ) => {
    setFormData({
      ...formData,
      [field]: value,
    })
  }

  return (
    <Dialog
      {...{ className }}
      innerClassName='bg-green'
      visible={contactModal}
      setVisible={toggleContactModal}
    >
      <div
        className={`contact__form bg-green ${
          success ? 'success' : ''
        } ${contactModalClass}`}
      >
        <div className='contact__form__header sticky top-0 z-1'>
          <button
            onClick={() => toggleContactModal()}
            className='naked white icon-close'
          />
          <h3 className='noMargin'>Contact this provider</h3>
        </div>
        <div className='contact__form__body px-5 py-8 grid gap-8 sm:grid-cols-2 <md:min-h-[calc(100vh-56px)]'>
          <div className='forms space-y-8'>
            <div className='form'>
              <span className='form__header mx-4 opacity-50'>
                Your contact info:
              </span>
              <form className='rounded-lg overflow-hidden shadow-md bg-white'>
                <Input
                  maxLength={40}
                  type='text'
                  autoComplete='given-name'
                  placeholder='First Name'
                  className='half !p-4'
                  value={formData.firstname}
                  onChange={e => updateField('firstname', e.target.value)}
                  error={errors.firstname}
                />
                <Input
                  maxLength={40}
                  type='text'
                  autoComplete='family-name'
                  placeholder='Last Name'
                  className='half !p-4'
                  value={formData.lastname}
                  onChange={e => updateField('lastname', e.target.value)}
                  error={errors.lastname}
                />
                <Input
                  maxLength={12}
                  type='text'
                  className='half !p-4'
                  value={formData.pronouns}
                  onChange={e => updateField('pronouns', e.target.value)}
                  placeholder='Your Pronouns'
                  error={errors.pronouns}
                />
                <Input
                  maxLength={24}
                  autoComplete='tel'
                  type='tel'
                  className='half !p-4'
                  value={formData.phone}
                  onChange={e => updateField('phone', e.target.value)}
                  placeholder='Phone Number'
                  error={errors.phone}
                />
                <Input
                  maxLength={40}
                  type='text'
                  className='!p-4'
                  value={formData.email}
                  onChange={e => updateField('email', e.target.value)}
                  placeholder='Email address'
                  error={errors.email}
                />
                <div className='checkbox__wrap !p-4'>
                  <Input
                    type='checkbox'
                    checked={formData.voicemail}
                    onChange={e => updateField('voicemail', e.target.checked)}
                    error={errors.voicemail}
                  />
                  <span>Okay to leave voicemail?</span>
                </div>
              </form>
            </div>
            <div className='form'>
              <span className='form__header mx-4 opacity-50'>
                Your message:
              </span>
              <form className='rounded-lg overflow-hidden shadow-md bg-white'>
                <textarea
                  placeholder='Message'
                  maxLength={300}
                  value={formData.message}
                  onChange={e => updateField('message', e.target.value)}
                  className={`!p-4 ${errors.message ? 'error' : ''}`}
                />
              </form>
            </div>
          </div>
          <div className='contact__form__body__emergency space-y-2 children:children:whitespace-nowrap'>
            <h3>In crisis or need urgent care?</h3>
            <p>
              If this is an emergency, <a href='tel:+1-911'>call 911</a>.
            </p>
            <p>
              Suicide prevention hotline:{' '}
              <a href='tel:+1800273TALK'>(800) 273-TALK</a>
            </p>
            <p>
              The Trevor Project (ages 13-24):{' '}
              <a href='tel:+18002467743'>(800) 246-7743</a>
            </p>
            <p>
              Trans Lifeline: <a href='tel:+18775658860<'>(877) 565-8860</a>
            </p>
            <p>
              Crisis Text Line: <a href='sms:+1741741'>Text GO to 741-741</a>
            </p>
          </div>
          <div className='contact__form__footer sm:col-span-2'>
            {loading ? (
              <Loader />
            ) : (
              <button
                onClick={() => submit()}
                className='w-full stack-x space-x-4 p-4 bg-white !text-indigo rounded-lg shadow-md !transition-all transform hover:scale-101 hover:shadow-lg'
              >
                <span className='flex-grow'>Send Inquiry</span>
                <IconMdiSend className='relative top-0.5' />
              </button>
            )}
          </div>
        </div>
        <div className='contact__form__success'>
          {professional && (
            <p>
              {`Your message has been delivered to ${`${professional.name} ${professional.last_name}`}.
                            If you do not receive a response from the provider within 48 hours, please reach out to us at `}
              <a href='mailto:support@lighthouse.lgbt'>
                support@lighthouse.lgbt
              </a>
              .
            </p>
          )}
          <p>Lighthouse is dedicated to connecting you to the right care.</p>
          <button className='naked' onClick={() => toggleContactModal()}>
            Close
          </button>
        </div>
      </div>
    </Dialog>
  )
}

export const ContactForm = connect()(_ContactForm)
