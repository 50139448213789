import { getProfessional } from '~/lib/professional'

import { GenericItem } from './search'
import { UIActionCreators } from './ui'

/**
 * Action types as consant
 */
export const SET_PROFESSIONAL = 'SET_PROFESSIONAL'
export const SET_LOADING = 'SET__PROFESSIONAL__LOADING'

export type Review = {
  name: string
  last_name: string
  comment: string
  rating: number
  hide_name: number
  score: number
  review_date: Date
}

export type Professional = {
  id: number
  name: string
  last_name: string
  health_profession: {
    name: string
    profession_type: string
  }
  professional_title: string
  services: Array<GenericItem>
  specialties: Array<GenericItem>
  price: string
  practice: string
  degrees: Array<GenericItem>
  certifications: Array<GenericItem>
  positions_held: Array<GenericItem>
  distance: number
  approach: string
  lgbtq_experience: string
  languages: Array<GenericItem>
  sexual_orientation: string
  gender: string
  insurances: Array<GenericItem>
  addresses: Array<{
    latitude: number
    longitude: number
    is_main: boolean
  }>
  reviews: Array<Review>
  url: string
  score: number
  show_phone: boolean
  phone: string
}

export type ProfessionalStore = {
  currentPro: Professional | null
  loading: boolean
}

const initialState: ProfessionalStore = {
  currentPro: null,
  loading: false,
}

/**
 * Professional reducer
 */
export default function ProfessionalReducer(
  state: ProfessionalStore = initialState,
  action: any
): ProfessionalStore {
  switch (action.type) {
    case SET_PROFESSIONAL:
      return {
        ...state,
        currentPro: action.data,
        loading: false,
      }
    case SET_LOADING:
      return { ...state, loading: action.loading }
    default:
      return state
  }
}
/**
 * Action creators
 */
const professionalActionCreators: {
  fetchDetailProfessional: Function
  setProfessional: Function
} = {
  fetchDetailProfessional: (id: number) => dispatch => {
    dispatch({
      loading: true,
      type: SET_LOADING,
    })

    return getProfessional(id)
      .then(response => {
        dispatch({
          data: response.professional,
          type: SET_PROFESSIONAL,
        })
      })
      .catch(err => {
        return Promise.reject(err?.message)
      })
  },
  setProfessional: (professional: Professional) => dispatch => {
    dispatch({
      data: professional,
      type: SET_PROFESSIONAL,
    })
  },
}

export { professionalActionCreators }
