import App from '~/components/app'
import type { ConnectedProps } from '~/connect'

const _ProviderView: React.FC<ConnectedProps> = ({
  professionalView: { currentPro, loading },
  toggleContactModal,
  ui: { contactModal },
}) => {
  // const navigate = useNavigate()

  return (
    <App className='public' title='Provider' page='provider'>
      <Container name='provider'>
        {!loading && currentPro !== null ? (
          <ProfessionalPublicView
            professional={currentPro}
            toggleContactForm={() => toggleContactModal(!contactModal)}
          />
        ) : (
          <Loader />
        )}
      </Container>
      {currentPro !== null && (
        <ContactForm professional={currentPro} visible={contactModal} />
      )}
    </App>
  )
}

export const ProviderView = connect()(_ProviderView)
