import cloneDeep from 'lodash-es/cloneDeep'
import uniq from 'lodash-es/uniq'

import {
  selectableInsuranceList,
  selectableServiceList,
  selectableLanguageList,
  selectableSpecialtyList,
  listProfessionals,
  autoCompleteSuggestionList,
} from '~/lib/search'
import { isEmptyString } from '~/lib/utils/global'

import { Professional } from './professional'
import { UIActionCreators } from './ui'

export const constructUrl = ({
  query,
  zip,
  filter,
  telemedicine,
}: SearchStore) => {
  return `?search=${encodeURIComponent(
    JSON.stringify({
      filter,
      query,
      telemedicine,
      zip,
    })
  )}`
}

declare let window: any

// Actions Constants
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const SET_FILTER = 'SET_FILTER'
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const APPEND_SEARCH_RESULTS = 'APPEND_SEARCH_RESULTS'
export const SET_QUERY_PARAM = 'SET_QUERY_PARAM'
export const SET_LOADING = 'SET_SEARCH_LOADING'
export const SET_SEARCH_FAILED = 'SET_SEARCH_FAILED'
export const SET_AUTOCOMPLETE = 'SET_AUTOCOMPLETE'
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'

export type GenericItem = {
  id: number
  name: string
}

export type FilterItem = GenericItem

export type Filter = {
  services: Array<FilterItem>
  specialties: Array<FilterItem>
  insurances: Array<FilterItem>
  languages: Array<FilterItem>
  genders: Array<FilterItem>
  orientations: Array<FilterItem>
  prices: Array<FilterItem>
}

export interface SearchParams {
  query: string
  zip: string
  page?: number | null
  filter: Filter
  telemedicine: null | {
    offered: string
    platform?: string
  }
}

/**
 * `filterData` is what's used to populate the search bar
 * `Filter` is what the user has actually selected and is used to construct the API search call
 */
export type SearchStore = {
  results: {
    professionals: Professional[]
    meta: {
      default_data: boolean
      total_entries: number
      total_pages: number
      current_page: number
    } | null
    message: string | null
    failed: boolean | null
    loading: boolean
    params: SearchParams | null
  }
  autoCompleteItems: Array<string>
  filterData: {
    services: {
      groups: Array<any>
      items: Array<FilterItem>
    } | null
    specialties: {
      groups: Array<any>
      items: Array<FilterItem>
    } | null
    languages: Array<FilterItem>
    insurances: Array<FilterItem>
    orientations: Array<FilterItem>
    genders: Array<FilterItem>
    prices: Array<FilterItem>
    fetched: boolean
  }
} & SearchParams

export const emptyFilter = {
  genders: [],
  insurances: [],
  languages: [],
  orientations: [],
  prices: [],
  services: [],
  specialties: [],
}

const initialState: SearchStore = {
  autoCompleteItems: [],
  filter: cloneDeep(emptyFilter),
  filterData: {
    fetched: false,
    genders: [
      'Cis man',
      'Cis woman',
      'Gender Nonconforming',
      'Intersex',
      'Transgender',
      'Trans man',
      'Trans woman',
    ].map((name, id) => ({ id, name })),
    insurances: [],
    languages: [],
    orientations: [
      'Asexual',
      'Bisexual',
      'Gay',
      'Lesbian',
      'Queer',
      'Pansexual',
      'Straight',
    ].map((name, id) => ({ id, name })),
    prices: ['$', '$$', '$$$', '$$$$'].map((name, id) => ({ id, name })),
    services: null,
    specialties: null,
  },
  query: '',
  results: {
    failed: null,
    loading: false,
    message: null,
    meta: null,
    params: null,
    professionals: [],
  },
  telemedicine: null,
  zip: '',
}

/**
 * Reducer
 */
export default function SearchReducer(
  state: SearchStore = initialState,
  action: any // TODO: TYPE
): SearchStore {
  switch (action.type) {
    case SET_FILTER_DATA:
      return {
        ...state,
        filterData: {
          ...cloneDeep(state.filterData),
          ...action.data,
        },
      }
    case SET_FILTER:
      return {
        ...state,
        filter: action.data,
      }
    case SET_QUERY_PARAM:
      return {
        ...state,
        [action.field]: action.value,
      }
    case SET_LOADING:
      return {
        ...state,
        results: {
          ...state.results,
          loading: true,
        },
      }
    case SET_SEARCH_RESULTS:
    case APPEND_SEARCH_RESULTS:
      return {
        ...state,
        results: {
          ...action.data,
          failed: false,
          loading: false,
          professionals:
            action.type === APPEND_SEARCH_RESULTS
              ? [...state.results.professionals, ...action.data.professionals]
              : action.data.professionals,
        },
      }
    case SET_SEARCH_PARAMS:
      return {
        ...state,
        filter: cloneDeep(action.data.filter),
        query: action.data.query,
        telemedicine: action.data.telemedicine,
        zip: action.data.zip,
      }
    case SET_AUTOCOMPLETE:
      return {
        ...state,
        autoCompleteItems: action.data,
      }
    case SET_SEARCH_FAILED:
      return {
        ...state,
        results: {
          ...state.results,
          failed: true,
          loading: false,
          message: null,
          meta: null,
        },
      }
    default:
      return state
  }
}

/**
 * Action creators
 */
const searchActionCreators: {
  updateFilter: Function
  fetchFilterData: Function
  fetchSearchResults: Function
  updateQueryParam: Function
  fetchAutocompleteData: Function
  updateSearchParams: Function
} = {
  /**
   * Fetches list of potential autocomplete optionss for main searchbar
   */
  fetchAutocompleteData: () => dispatch => {
    autoCompleteSuggestionList()
      .then(res => {
        dispatch({
          data: res.map((a, i) => a.name),
          type: SET_AUTOCOMPLETE,
        })
      })
      .catch(e => {
        // Report error to sentry
        console.error('Unable to fetch autocomplete data')
      })
  },

  /**
   * Data needed to populate filter options in searchFilter component
   */
  fetchFilterData: () => dispatch => {
    Promise.all([
      selectableInsuranceList(),
      selectableServiceList(),
      selectableLanguageList(),
      selectableSpecialtyList(),
    ])
      .then(values => {
        dispatch({
          data: {
            fetched: true,
            insurances: values[0].insurances,
            languages: values[2].languages,
            services: {
              field: 'type_service_name',
              groups: uniq(values[1].services.map(s => s.type_service_name)),
              items: values[1].services,
            },
            specialties: {
              field: 'type_specialty_name',
              groups: uniq(
                values[3].specialties.map(s => s.type_specialty_name)
              ),
              items: values[3].specialties,
            },
          },
          type: SET_FILTER_DATA,
        })
      })
      .catch(errors => {
        console.log(errors)
        UIActionCreators.toggleSnackbar(
          'Lighthouse search is currently not working. Please check back later.',
          true
        )(dispatch)
      })
  },

  fetchSearchResults:
    (params: SearchParams, context = 'default') =>
    (dispatch, getState) => {
      if (params == null) {
        params = getState().search
      }

      if (isEmptyString(params.zip)) {
        return dispatch({
          data: {
            message: 'Start your search by entering a zipcode.',
            professionals: [],
          },
          type: SET_SEARCH_RESULTS,
        })
      } else if (
        isNaN(Number(params.zip)) ||
        params.zip.toString().length < 5
      ) {
        return dispatch({
          data: {
            message: 'Please enter a valid zip code.',
            professionals: [],
          },
          type: SET_SEARCH_RESULTS,
        })
      }

      if (window.analytics !== null) {
        window.analytics.track('Search performed', {
          context,
          parameters: getParamsFromState(params),
        })
      }

      dispatch({
        loading: true,
        type: SET_LOADING,
      })

      listProfessionals(params)
        .then(results => {
          dispatch({
            data: {
              ...results,
              message: null,
              params,
            },
            type: isNaN(Number(params.page))
              ? SET_SEARCH_RESULTS
              : APPEND_SEARCH_RESULTS,
          })
        })
        .catch(err => {
          dispatch({ type: SET_SEARCH_FAILED })
          UIActionCreators.toggleSnackbar(
            'Lighthouse search is currently not working. Please check back later.',
            true
          )(dispatch)
        })
    },
  updateFilter:
    (filter: any | null, reset = false) =>
    dispatch => {
      if (reset) {
        dispatch({
          data: emptyFilter,
          type: SET_FILTER,
        })
      } else {
        dispatch({
          data: filter,
          type: SET_FILTER,
        })
      }

      return Promise.resolve()
    },
  updateQueryParam:
    (field: 'query' | 'zip' | 'telemedicine', value) => dispatch => {
      dispatch({
        field,
        type: SET_QUERY_PARAM,
        value,
      })

      return Promise.resolve()
    },
  updateSearchParams: (params: SearchParams) => dispatch => {
    dispatch({
      data: params,
      type: SET_SEARCH_PARAMS,
    })

    return Promise.resolve()
  },
}

// Helper function that pulls search params from search state
export const getParamsFromState = state => {
  const { filterData, autoCompleteItems, results, ...searchParams } = state

  return searchParams
}

export { searchActionCreators }
