import App from '~/components/app'
import type { ConnectedProps } from '~/connect'

import '~/styles/pages/search.scss'

const Search: React.FC<ConnectedProps> = ({
  fetchSearchResults,
  professionalView: { currentPro },
  search: {
    results: { professionals },
  },
  toggleContactModal,
  ui: { contactModal, contactModalClass },
  updateSearchParams,
}) => {
  useEffect(() => {
    if (professionals.length)
      tc(
        () =>
          updateSearchParams(
            deconstructUrlSearchParams(window.location.href)
          ).then(() => fetchSearchResults()),
        () => fetchSearchResults()
      )
  }, [])

  return (
    <App
      className='public'
      page='search'
      title='Search'
      footerClass='space'
      outer={
        <button
          className='urgent__button red__bg fat'
          onClick={() => toggleContactModal(true, 'info')}
        >
          Urgent Care
        </button>
      }
    >
      <Container name='search' innerClass='px-4'>
        <SearchPanel />
        <SearchResults />
      </Container>
      <ContactForm
        className={contactModalClass}
        professional={currentPro}
        visible={contactModal}
      />
    </App>
  )
}

export default connect()(Search)
