import type { FC } from 'react'

import { createFadeIn } from './create-fade-in'

type Props = {
  children: JSX.Element[] | JSX.Element | boolean | null
  name: string
  className?: string
  withFade?: boolean
  loaded?: boolean
  navText?: string
  innerClass?: string
}

const Children = createFadeIn<Props>(
  ({ innerClass, children, name }: Props) => (
    <div className={`${name} ${innerClass}`}>{children}</div>
  )
)

export const Container: FC<Props> = ({ className = '', ...props }) => (
  <div className={`${props.name}-container ${className}`}>
    <Navbar text={props.navText} />
    <Children {...props} />
  </div>
)
