export type BlogPost = {
  title: string
  description: string
  link: string
  date: Date
  image: string | null
  content: string
}

export const transformToBlogPost = (blogFromRes: any): BlogPost => {
  let image = null

  if (
    blogFromRes._embedded !== undefined &&
    blogFromRes._embedded['wp:featuredmedia'] !== undefined &&
    blogFromRes._embedded['wp:featuredmedia'].length > 0
  ) {
    image = blogFromRes._embedded['wp:featuredmedia'][0].source_url
  }

  return {
    content: blogFromRes.content.rendered,
    date: new Date(blogFromRes.date),
    description: blogFromRes.excerpt.rendered,
    image,
    link: blogFromRes.link,
    title: blogFromRes.title.rendered,
  }
}
