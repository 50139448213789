// const moment = require("moment");

export const pretty = (date: Date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString("en-US", options);
}

// export const fromNow = timestamp => {
//     return moment(timestamp).fromNow();
// };

// export const formatTime = (timestamp, format = 'MMMM Do, YYYY') => {
//     return moment(timestamp).format(format);
// }