// import * as React from "react";
// import { RouteComponentProps } from "react-router";
// import Loading from "~/components/home/loading";
// // import LoadingCmp from "~/components/global/loading";

// type Props = {
//     saveUser: Function;
//     Component: any;
//     refreshUser: Function;
// } & RouteComponentProps<{}>;

// /**
//  * Wrapper route cmp. that manages a loading state
//  * until we load our user from the cache
//  */
// class Protected extends React.Component<Props> {
//     componentDidMount() {
//         if (this.props.user.shouldRefresh) {
//             this.props.refreshUser();
//         }
//     }

//     render() {
//         const { Component, user, ...rest } = this.props;

//         return user.shouldRefresh ? <Loading /> : <Component {...rest} />;
//     }
// }

// export default connect()(Protected);

import type { FC } from 'react'

const ProtectedPage: FC = () => <div>Protected</div>

export default ProtectedPage
