import type { FC } from 'react'

import '~/styles/components/dialog.scss'

// const Inner = createFadeIn<{
//   children: JSX.Element[] | JSX.Element | any
// }>(props => (
//   <div className='dialog__inner' {...props}>
//     {props.children}
//   </div>
// ))

export const Dialog: FC<{
  className?: string
  children: JSX.Element[] | JSX.Element | any
  innerClassName?: string
  visible: boolean
  setVisible: Function
}> = ({
  className = '',
  children,
  innerClassName = '',
  setVisible,
  visible,
}) => {
  const inner = useRef<HTMLDivElement>(null)
  // const [innerVisible, setInnerVisible] = useState(false)

  const handleClick: React.MouseEventHandler = e => {
    if (!inner.current?.contains(e.target as Node)) setVisible(false)
  }

  useEffect(() => {
    document.body.classList[visible ? 'add' : 'remove']('withModal')
  }, [visible])

  return (
    <div
      className={`dialog inset-0 transform-none ${className} ${
        visible ? 'visible' : ''
      }`}
      onClick={e => handleClick(e)}
    >
      <div className='dialog__background' />
      <div className={`dialog__inner ${innerClassName}`} ref={inner}>
        {children}
      </div>
      {/* // <Inner loaded={visible} children={this.props.children} ref={inner => this.inner = inner} /> */}
    </div>
  )
}
