import type { ConnectedProps } from '~/connect'

const _SearchResults: React.FC<ConnectedProps> = ({
  fetchSearchResults,
  search: {
    results: { failed, loading, message, meta, params, professionals },
  },
}) => {
  const handleScrollInfinite = () => {
    if (
      window.scrollY + window.innerHeight + 500 >= document.body.scrollHeight &&
      !loading &&
      professionals.length &&
      meta != null &&
      !meta.default_data &&
      meta.current_page < meta.total_pages
    )
      fetchSearchResults({
        ...params,
        page: meta.current_page + 1,
      })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollInfinite)

    return () => window.removeEventListener('scroll', handleScrollInfinite)
  }, [handleScrollInfinite])

  return (
    <div className='<md:pt-4 py-16'>
      {loading && meta == null ? (
        <Loader />
      ) : message ? (
        <div className='search__results__message'>
          <h2>{message}</h2>
        </div>
      ) : failed ? (
        <div className='search__results__message'>
          <h3>
            Lighthouse is experiencing some technical issues. Please try your
            search again later.
          </h3>
        </div>
      ) : meta?.default_data ? (
        <div className='search__results__message'>
          <h3>
            Lighthouse doesn't currently have any providers that match your
            search! We've made a note and will do our best to add more providers
            soon.
          </h3>
          <h3>
            Please try updating your search or one of your filters to see other
            providers in your area.
          </h3>
        </div>
      ) : (
        <>
          <ul className='space-y-4'>
            {professionals.map((p, i) => (
              <li key={p.id}>
                <SearchProfessional
                  className='bg-white p-4 rounded-xl shadow-md border border-gray border-solid border-opacity-10 max-w-180 mx-auto'
                  professional={p}
                />
              </li>
            ))}
          </ul>
          {loading && <Loader className='my-4 mx-auto' />}
        </>
      )}
    </div>
  )
}

export const SearchResults = connect()(_SearchResults)
