let segmentInitialized = false

export const useAnalytics = () => {
  useEffect(() => {
    const id = import.meta.env.VITE_REACT_APP_SEGMENT_ID as string | undefined

    if (id && !segmentInitialized)
      try {
        segmentInitialized = true
        window.analytics?.load(id)
      } catch (e) {
        segmentInitialized = false
        console.error(e)
      }
  }, [])

  return window.analytics as typeof window.analytics | undefined
}
