import _src_pages_contact_tsx from "/src/pages/contact.tsx";
import _src_pages_index_tsx from "/src/pages/index.tsx";
import _src_pages_logout_tsx from "/src/pages/logout.tsx";
import _src_pages_privacy_tsx from "/src/pages/privacy.tsx";
import _src_pages_protected_tsx from "/src/pages/protected.tsx";
import _src_pages_search_tsx from "/src/pages/search.tsx";
import _src_pages_terms_tsx from "/src/pages/terms.tsx";
import _src_pages_blog_$slug$_tsx from "/src/pages/blog/[slug].tsx";
import _src_pages_provider_$id$_tsx from "/src/pages/provider/[id].tsx";
import _src_pages_$___all$_tsx from "/src/pages/[...all].tsx";
import React from "react";

const routes = [{"path":"contact","element":React.createElement(_src_pages_contact_tsx)},{"element":React.createElement(_src_pages_index_tsx),"index":true},{"path":"logout","element":React.createElement(_src_pages_logout_tsx)},{"path":"privacy","element":React.createElement(_src_pages_privacy_tsx)},{"path":"protected","element":React.createElement(_src_pages_protected_tsx)},{"path":"search","element":React.createElement(_src_pages_search_tsx)},{"path":"terms","element":React.createElement(_src_pages_terms_tsx)},{"path":"blog","children":[{"path":":slug","element":React.createElement(_src_pages_blog_$slug$_tsx)}]},{"path":"provider","children":[{"path":":id","element":React.createElement(_src_pages_provider_$id$_tsx)}]},{"path":"*","element":React.createElement(_src_pages_$___all$_tsx)}];

export default routes;