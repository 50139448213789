import { pretty } from '~/lib/utils/time'
import type { BlogPost } from '~/store/modules/blog'
import { transformToBlogPost } from '~/store/modules/blog'

export const BlogPosts: React.FC<{ className?: string }> = ({
  className = '',
}) => {
  const [loading, setLoading] = useState(true)
  const [posts, setPosts] = useState<BlogPost[]>([])

  useEffect(() => {
    ;(async () => {
      const posts = await getBlogPosts()
      setPosts(posts.map(transformToBlogPost))
      setLoading(false)
    })()
  }, [])

  return (
    <div className={`home__blog__posts__wrap stack-y space-y-8 ${className}`}>
      <h2 className='text-center text-white !m-0 text-xl'>
        The Lighthouse blog
      </h2>
      {loading ? (
        <Loader className='dark' />
      ) : (
        <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
          {posts.map(({ date, description, image, link, title }, i) => (
            <div
              key={link}
              className='h-96 bg-white rounded-lg overflow-hidden shadow hover:shadow-lg stack-y items-stretch cursor-pointer transition transform hover:(-translate-y-px scale-101)'
              onClick={() => window.open(link, '_blank')}
            >
              <img
                className='flex-grow object-cover'
                src={image !== null ? image : ''}
              />
              <div className='relative'>
                <h3
                  dangerouslySetInnerHTML={{ __html: title }}
                  className='-mb-px absolute bottom-0 line-clamp-3 max-w-56 rounded-t bg-white px-2 pt-2'
                />
              </div>
              <div
                className='flex-shrink-0 px-2 pt-2 line-clamp-3'
                dangerouslySetInnerHTML={{ __html: description }}
              />
              <p className='flex-shrink-0 px-2 pb-2 pt-1 text-xs opacity-25 text-right'>
                {pretty(date)}
              </p>
            </div>
          ))}
        </div>
      )}
      <a
        className='naked button__link self-end white text-md inline-flex space-x-1'
        href='https://blog.lighthouse.lgbt'
      >
        <span>Read more</span>
        <IconMdiLaunch className='text-sm relative -top-2px' />
      </a>
    </div>
  )
}
