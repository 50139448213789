import cloneDeep from 'lodash-es/cloneDeep'

import type { SearchParams } from '~/store/modules/search'
import { emptyFilter } from '~/store/modules/search'

import { get } from './api'
import { isEmptyString } from './utils/global'
import { getQueryParam } from './utils/url'

// Used to match pretty keys to keys used for API call
export const prettyNameAssociation = {
  gender: 'gender_eq_any',
  insurances: 'insurance_ids',
  languages: 'language_ids',
  orientation: 'sexual_orientation_eq_any',
  prices: 'price_lteq',
  services: 'service_ids',
  specialties: 'specialty_ids',
}

export const listProfessionals = (passedParams: SearchParams) => {
  const params: SearchParams = cloneDeep(passedParams)

  console.log(passedParams)

  // Map search params into lists of numerical ids
  Object.keys(params.filter).forEach((key, i) => {
    params.filter[key] = params.filter[key].map(i => i.id)
  })

  return get(`/list_professionals`, {
    '[insurance_ids]': params.filter.insurances,
    '[language_ids]': params.filter.languages,
    '[service_ids]': params.filter.services,
    '[specialty_ids]': params.filter.specialties,
    latitude: null,
    longitude: null,
    page: params.page,
    'q[freetext_matches_any][]': isEmptyString(params.query)
      ? null
      : params.query,
    'q[gender_eq_any]': params.filter.genders,
    'q[price_lteq]':
      params.filter.prices.length > 0 ? params.filter.prices[0] : null,
    'q[sexual_orientation_eq_any]': params.filter.orientations,
    telemedicine: params.telemedicine,
    zipcode: isEmptyString(params.zip) ? null : params.zip,
  })
}

export const selectableInsuranceList = () =>
  get(`/get_selectable_insurance_list`)

export const selectableServiceList = () => get(`/get_selectable_service_list`)

export const selectableSpecialtyList = () =>
  get(`/get_selectable_specialty_list`)

export const selectableLanguageList = () => get(`/get_selectable_language_list`)

export const autoCompleteSuggestionList = () =>
  get(`/get_non_provider_autocomplete_suggestion_list`)

export const deconstructUrlSearchParams = (windowUrl: string) => {
  const urlSearchParam = JSON.parse(getQueryParam(windowUrl, 'search'))

  // Check validity of parsed search param object
  if (urlSearchParam !== null && !checkUrlParam(urlSearchParam)) {
    console.log(urlSearchParam)

    return {
      filter: urlSearchParam.filter,
      query: urlSearchParam.query,
      telemedicine: urlSearchParam.telemedicine,
      zip: urlSearchParam.zip,
    }
  } else {
    throw 'No valid search params in URL.'
  }
}

export const checkUrlParam = (params: SearchParams): boolean => {
  // Check validity of parsed object
  if ('query' in params && 'zip' in params && 'filter' in params) return false
  if (typeof params.query !== 'string' || typeof params.zip !== 'string')
    return false
  // Check if all filter keys match keys in state filter object
  if (
    Object.keys(params.filter).filter(
      key => !Object.keys(emptyFilter).includes(key)
    ).length !== 0
  )
    return false

  return true
}
