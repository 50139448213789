import type { FC } from 'react'

export const Input: FC<{
  autoComplete?: string
  className?: string
  error?: boolean
  maxLength?: number
  type?: string
}> = props => (
  <input
    {...props}
    className={`${props.error ? 'error ' : ''}${props.className || ''}`}
  />
)
