import type { ConnectedProps } from '~/connect'
import { constructUrl } from '~/store/modules/search'
import type { FilterItem } from '~/store/modules/search'
import { screenWidths } from '~/styles'

const _SearchFilter: React.FC<ConnectedProps> = ({
  fetchFilterData,
  fetchSearchResults,
  search,
  updateFilter,
  updateQueryParam,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [filterItems, setFilterItems] = useState([])
  const [selectedFilterCategory, setSelectedFilterCategory] = useState<
    keyof typeof search['filterData'] | null
  >(null)
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null)

  const navigate = useNavigate()
  const performSearch = () => {
    navigate({
      pathname: '/search',
      search: constructUrl(search),
    })

    fetchSearchResults()
  }

  useEffect(() => {
    fetchFilterData()
  }, [])

  const selectFilterCategory = (
    x: Exclude<typeof selectedFilterCategory, null>
  ) => {
    if (x === selectedFilterCategory && selectedGroup === null) {
      setDropdownVisible(false)
      setTimeout(() => {
        setFilterItems([])
        setSelectedFilterCategory(null)
        setSelectedGroup(null)
      }, 400)

      return
    }

    setDropdownVisible(true)
    setFilterItems(search.filterData[x]?.groups || search.filterData[x])
    setSelectedFilterCategory(x)
    setSelectedGroup(null)
  }

  const selectGroup = (x: Exclude<typeof selectedGroup, null>) => {
    if (!selectedFilterCategory) return

    setFilterItems(
      search.filterData[selectedFilterCategory]?.items?.filter(
        item => item[search.filterData[selectedFilterCategory]?.field] === x
      )
    )
    setSelectedGroup(x)
  }

  const toggleFilter = async (
    add: boolean,
    filter: FilterItem,
    filterCategory: string | null = null
  ) => {
    const k = filterCategory || selectedFilterCategory
    if (k == null) return

    await updateFilter({
      ...search.filter,
      [k]: add
        ? [...search.filter[k], filter]
        : search.filter[k].filter(x => x.id != filter.id),
    })

    performSearch()
  }

  const formatCategoryString = (field, pretty, plural?: string): string => {
    const l = search.filter[field].length

    return l
      ? plural
        ? `${l} ${l > 1 ? plural : pretty}`
        : `${l} ${pretty}${l > 1 ? 's' : ''}`
      : pretty
  }

  const toggleTelehealth = async () => {
    await updateQueryParam(
      'telemedicine',
      search.telemedicine?.offered ? null : { offered: true }
    )
    performSearch()
  }

  // 6 is the database ID for skype therapy
  const telehealthSelected =
    search.telemedicine !== null && search.telemedicine.offered

  // Check any filters are present
  const anyFiltersPresent: boolean =
    Object.keys(search.filter).filter(key => search.filter[key].length > 0)
      .length > 0

  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const { sm } = screenWidths

  return (
    <div
      {...{ ref }}
      className='text-xs <sm:space-y-2 sm:(flex items-baseline space-x-8)'
    >
      <span className='flex-shrink-0 opacity-50'>
        {anyFiltersPresent ? 'Filtering' : 'Filter'} by:
      </span>
      <div className='space-y-4'>
        <div>
          <div className='-m-1 children:m-1'>
            <button
              onClick={() => selectFilterCategory('services')}
              className={
                (anyFiltersPresent
                  ? !selectedFilterCategory && search.filter.services.length
                  : !selectedFilterCategory) ||
                selectedFilterCategory === 'services'
                  ? 'bg-indigo'
                  : 'bg-white text-black'
              }
            >
              {formatCategoryString('services', 'service')}
            </button>
            <button
              onClick={() => selectFilterCategory('specialties')}
              className={
                (anyFiltersPresent
                  ? !selectedFilterCategory && search.filter.specialties.length
                  : !selectedFilterCategory) ||
                selectedFilterCategory === 'specialties'
                  ? 'bg-teal'
                  : 'bg-white text-black'
              }
            >
              {formatCategoryString('specialties', 'specialty', 'specialties')}
            </button>
            {/* <button onClick={() => this.selectFilterCategory("profession")}
                            className="red__bg white">profession</button> */}
            <button
              onClick={() => selectFilterCategory('genders')}
              className={
                (anyFiltersPresent
                  ? !selectedFilterCategory && search.filter.genders.length
                  : !selectedFilterCategory) ||
                selectedFilterCategory === 'genders'
                  ? 'bg-pink'
                  : 'bg-white text-black'
              }
            >
              {formatCategoryString('genders', 'gender')}
            </button>
            <button
              onClick={() => selectFilterCategory('orientations')}
              className={
                (anyFiltersPresent
                  ? !selectedFilterCategory && search.filter.orientations.length
                  : !selectedFilterCategory) ||
                selectedFilterCategory === 'orientations'
                  ? 'bg-pink'
                  : 'bg-white text-black'
              }
            >
              {formatCategoryString('orientations', 'orientation')}
            </button>
            <button
              onClick={() => selectFilterCategory('languages')}
              className={
                (anyFiltersPresent
                  ? !selectedFilterCategory && search.filter.languages.length
                  : !selectedFilterCategory) ||
                selectedFilterCategory === 'languages'
                  ? 'bg-indigo'
                  : 'bg-white text-black'
              }
            >
              {formatCategoryString('languages', 'language')}
            </button>
            <button
              onClick={() => selectFilterCategory('prices')}
              className={
                (anyFiltersPresent
                  ? !selectedFilterCategory && search.filter.prices.length
                  : !selectedFilterCategory) ||
                selectedFilterCategory === 'prices'
                  ? 'bg-orange'
                  : 'bg-white text-black'
              }
            >
              {formatCategoryString('prices', 'price')}
            </button>
            <button
              onClick={() => selectFilterCategory('insurances')}
              className={`text-black ${
                (anyFiltersPresent
                  ? !selectedFilterCategory && search.filter.insurances.length
                  : !selectedFilterCategory) ||
                selectedFilterCategory === 'insurances'
                  ? 'bg-yellow'
                  : 'bg-white'
              }`}
            >
              {formatCategoryString('insurances', 'insurance')}
            </button>
            {/* <button onClick={() => this.toggleTelehealth()} className={telehealthSelected ? 'blue__bg' : 'white__bg black'}
                        >telehealth</button> */}
            {anyFiltersPresent && (
              <button
                className='searchPanel__filter__reset naked red'
                onClick={() =>
                  updateFilter(null, true).then(() => performSearch())
                }
              >
                Reset my filters
              </button>
            )}
          </div>
        </div>
        {dropdownVisible && (
          <div className='space-y-2'>
            <span className='opacity-50'>
              Tap {selectedFilterCategory} to filter
            </span>
            <div>
              <div className='-m-1 children:m-1 overflow-x-hidden overflow-y-scroll max-h-50vh'>
                {selectedFilterCategory &&
                  filterItems.map((x, i) => {
                    const selected = search.filter[
                      selectedFilterCategory
                    ].filter(
                      x.id === undefined
                        ? y =>
                            y[
                              search.filterData[selectedFilterCategory].field
                            ] === x
                        : i => i.id === x.id
                    ).length

                    return (
                      <button
                        onClick={() =>
                          x.id !== undefined
                            ? toggleFilter(!selected, x)
                            : selectGroup(x)
                        }
                        key={i}
                        className={selected ? 'blue__bg' : 'white__bg black'}
                      >
                        {x.name ?? x}
                      </button>
                    )
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export const SearchFilter = connect()(_SearchFilter)
