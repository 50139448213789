import defaultColors from 'windicss/colors'
import type { Theme } from 'windicss/types/interfaces'

export const colors = {
  gray: {
    ...defaultColors.gray,
    50: '#FBFBFB',
    800: '#474448',
    DEFAULT: '#474448',
  },
  green: {
    ...defaultColors.green,
    400: '#06D6A0',
    500: '#14BD91',
    DEFAULT: '#06D6A0',
  },
  indigo: {
    ...defaultColors.indigo,
    400: '#92A8D1',
    DEFAULT: '#92A8D1',
  },
  orange: {
    ...defaultColors.orange,
    300: '#F7C27E',
    DEFAULT: '#F7C27E',
  },
  pink: {
    ...defaultColors.pink,
    300: '#F7CAC9',
    DEFAULT: '#F7CAC9',
  },
  purple: {
    ...defaultColors.purple,
    400: '#C3A2FA',
    DEFAULT: '#C3A2FA',
  },
  red: {
    ...defaultColors.red,
    500: '#E85F5C',
    DEFAULT: '#E85F5C',
  },
  teal: {
    ...defaultColors.teal,
    400: '#34DECE',
    DEFAULT: '#34DECE',
  },
  yellow: {
    ...defaultColors.yellow,
    300: '#FFF06E',
    DEFAULT: '#FFF06E',
  },

  // Third-party brand colors
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  facebook: '#3B5998',
  instagram: '#E1306C',
  tumblr: '#35465C',
  twitter: '#55ACEE',
} as const
export type AppColors = typeof colors &
  Readonly<{
    white: '#FFFFFF'
    black: '#000000'
  }>

export const screens = {
  '2xl': '1536px',
  '2xs': '320px',
  '3xs': '240px',
  lg: '1024px',
  md: '768px',
  sm: '640px',
  xl: '1280px',
  xs: '480px',
} as const
export type AppScreens = typeof screens

export type AppScreenWidths = Record<keyof AppScreens, number>
export const screenWidths = Object.entries(screens).reduce(
  (acc: Partial<AppScreenWidths>, [k, v]): typeof acc => ({
    ...acc,
    [k]: parseInt(v.replace('px', '')),
  }),
  {}
) as AppScreenWidths

export const spacing = {
  ...screens,
  '2x': '200%',
  '3x': '300%',
  '4x': '400%',
} as const
export type AppSpacing = typeof spacing & {
  [key: `${number}`]: `${number}rem`
}

const theme: Theme = {
  colors: {
    ...defaultColors,
    ...colors,
  },
  extend: {
    // borderColor: {
    //   DEFAULT: colors.gray[800],
    // },
    // borderOpacity: {
    //   DEFAULT: 0.3,
    // },
    spacing,
    typography: {
      DEFAULT: {
        css: {
          a: {
            '&:hover': {
              color: colors?.indigo.DEFAULT ?? '#92A8D1',
              opacity: 1,
            },
            color: 'inherit',
            fontWeight: '600',
            opacity: 0.75,
            textDecoration: 'underline',
          },
          b: { color: 'inherit' },
          code: { color: 'inherit' },
          color: 'inherit',
          em: { color: 'inherit' },
          h1: { color: 'inherit' },
          h2: { color: 'inherit' },
          h3: { color: 'inherit' },
          h4: { color: 'inherit' },
          maxWidth: '65ch',
          strong: { color: 'inherit' },
        },
      },
    },
    ...[
      'height',
      'maxHeight',
      'minHeight',
      'width',
      'maxWidth',
      'minWidth',
    ].reduce((acc, k) => ({ ...acc, [k]: spacing }), {}),
  },
  screens,
}

export default theme
