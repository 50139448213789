import type { FC } from 'react'

import type { ConnectedProps } from '~/connect'

import { SearchBar } from './search-bar'
import { SearchFilter } from './search-filter'

const _SearchPanel: FC<ConnectedProps> = () => (
  <div className='searchPanel bg-white border border-gray border-solid border-opacity-10 rounded-xl shadow-md children:p-4'>
    <SearchBar className='relative after:(content absolute bottom-0 inset-x-0 border-0 border-b border-solid border-gray border-opacity-10)' />
    <SearchFilter />
  </div>
)

export const SearchPanel = connect()(_SearchPanel)
