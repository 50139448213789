// import { isLoggedIn } from "./utilities/user";

import type { AxiosInstance } from 'axios'
import axios, { AxiosAdapter } from 'axios'

const override: string | null = null
// override = 'https://api.lighthouse.lgbt/api/v1';

// Create Axios instance with base paramaters
const instance: AxiosInstance = axios.create({
  baseURL: override ?? (import.meta.env.VITE_REACT_APP_API_URL as string),
  timeout: 6000,
})

const baseHeadersForRequest = () => {
  return {
    Authorization: '',
  }
}

type Error = {
  status: number
  error: string
  code: number
  stack: any
}

/**
 * Format error from Axios request for us
 * @param err Axios error object
 */
export const axiosErr = (err): Error | null => {
  if (err.response && err.response.data) {
    if (err.response.data.code) {
      return errResponses[err.response.data.code] || err.response.data.error
    } else {
      return err.response.data
    }
  }

  return null
}

export const get = async (
  url: string,
  params?: any | null,
  customInstance?: AxiosInstance
) => {
  const instanceForGet: AxiosInstance =
    customInstance !== undefined ? customInstance : instance

  return instanceForGet({
    headers: baseHeadersForRequest(),
    method: 'GET',
    params: params !== null ? params : null,
    url,
  })
    .then(res => res.data)
    .catch(error => Promise.reject(axiosErr(error)))
}

export const post = async (url: string, params: Object) => {
  return instance({
    data: params,
    headers: baseHeadersForRequest(),
    method: 'POST',
    url,
  })
}

export const put = async (url: string, params: Object) => {
  return instance({
    data: params,
    headers: baseHeadersForRequest(),
    method: 'PUT',
    url,
  })
}

export const deleteReq = async (url: string, params: Object | null) => {
  return instance({
    data: params,
    headers: baseHeadersForRequest(),
    method: 'DELETE',
    url,
  })
}

const client = {
  deleteReq,
  get,
  post,
  put,
}

export const errResponses = {
  ERR_INVALID_EMAIL: 'The email you entered is not valid.',
  ERR_INVALID_PHONE: 'The phone number you entered is not valid.',
  ERR_UNKNOWN:
    'It looks like something went wrong on our end! Please contact support@lighthouse.lgbt for assistance.',
}

export default client
