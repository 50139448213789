import type { To } from 'react-router-dom'

import type { ConnectedProps } from '~/connect'
import { pluralize } from '~/lib/utils/global'
import type { Professional } from '~/store/modules/professional'
import type { FilterItem } from '~/store/modules/search'
import { constructUrl } from '~/store/modules/search'
import { screenWidths } from '~/styles'

const MAX_SERVICES_SHOWN_COUNT = 4

const _SearchProfessional: React.FC<
  ConnectedProps & {
    className?: string
    professional: Professional
  }
> = ({
  className = '',
  fetchSearchResults,
  professional,
  search,
  setProfessional,
  toggleContactModal,
  ui,
  updateFilter,
}) => {
  const navigate = useNavigate()
  const toggleService = async (
    event: React.MouseEvent,
    serviceToAdd: FilterItem,
    add?: boolean
  ) => {
    event.stopPropagation()

    await updateFilter({
      ...search.filter,
      services: add
        ? [...search.filter.services, serviceToAdd]
        : search.filter.services.filter(i => i.id !== serviceToAdd.id),
    })

    navigate({ search: constructUrl(search) })
    fetchSearchResults()
  }

  const goToPro = (e: React.MouseEvent, to: To) => {
    if (e.target === e.currentTarget) {
      e.preventDefault()
      e.stopPropagation()

      if (e.ctrlKey || e.shiftKey || e.metaKey || e.button === 1)
        window.open(typeof to === 'string' ? to : to.pathname, '_blank')
      else navigate(to)
    }
  }

  const to: To = {
    pathname: `/provider/${professional.id}`,
  }

  const { width } = useWindowSize()
  const { sm } = screenWidths

  return (
    <div
      className={`<sm:space-y-4 sm:(stack-x items-stretch space-x-4 relative) ${className}`}
      onClick={e => goToPro(e, to)}
    >
      {width >= sm && (
        <Link {...{ to }} className='sm:flex-shrink-0'>
          <img
            className='w-20 h-20 object-cover rounded-full'
            src={professional.url}
          />
        </Link>
      )}
      <div className='space-y-2 sm:flex-auto'>
        <Link {...{ to }} className='flex space-x-4 sm:flex-shrink-0'>
          {width < sm && (
            <img
              className='flex-shrink-0 w-12 h-12 object-cover rounded-full'
              src={professional.url}
            />
          )}
          <div className='flex-grow'>
            <h3 className='m-0'>
              {professional.name} {professional.last_name}
            </h3>
            <p className='lighthouse-text-light'>
              {professional.health_profession.name}
            </p>
          </div>
        </Link>
        <div className='-m-1 children:m-1'>
          {professional.services
            .slice(0, MAX_SERVICES_SHOWN_COUNT + 1)
            .map((x, i) => {
              if (
                i === MAX_SERVICES_SHOWN_COUNT &&
                professional.services.length > MAX_SERVICES_SHOWN_COUNT
              )
                return (
                  <button
                    key='service-more'
                    className='unclickable white__bg black text-sm font-semibold'
                  >
                    {`+ ${
                      professional.services.length - MAX_SERVICES_SHOWN_COUNT
                    } more ${pluralize(
                      'service',
                      professional.services.length - i
                    )}`}
                  </button>
                )

              const selected = !!search.filter.services.filter(
                i => i.id === x.id
              ).length

              return (
                <button
                  key={`service-${x.id}`}
                  className={`text-sm font-semibold ${
                    selected ? 'blue__bg white' : 'white__bg black'
                  }`}
                  onClick={e => toggleService(e, x, !selected)}
                >
                  {x.name}
                </button>
              )
            })}
        </div>
        <div className='stack-x justify-start space-x-4'>
          <p>
            {professional.price}
            <span className='lighthouse-text-ultralight'>
              {new Array(4 - professional.price.length).fill('$').join('')}
            </span>
          </p>
          <p>{`${professional.distance} mi`}</p>
        </div>
      </div>
      <div className='space-x-4 <sm:(stack-x justify-end) sm:(absolute top-4 right-4)'>
        <Link {...{ to }} className='button__link naked black'>
          More Info
        </Link>
        <button
          className='bg-teal'
          onClick={e => {
            e.stopPropagation()

            setProfessional(professional)
            toggleContactModal(!ui.contactModal)
          }}
        >
          Contact Now
        </button>
      </div>
    </div>
  )
}

export const SearchProfessional = connect()(_SearchProfessional)
